import brandCompany from './admin/brandcompany';
import adminCollection from './admin/collection';
import { customers } from './admin/customers';
import adminOrders from './admin/orders';
import paymentTypes from './admin/paymentTypes';
import { retailerCompany } from './admin/retailercompany';
import tags from './admin/tags';
import retailerAccount from './buyers/account';
import cart from './buyers/cart';
import cms from './buyers/cms';
import buyersOrder from './buyers/order';
import orderForm from './buyers/order-form';
import payment from './buyers/payment';
import { profile as retailerProfile } from './buyers/profile';
import { recommendations } from './buyers/recommendations';
import { sizeCurves } from './buyers/sizeCurves';
import { storeAddress } from './buyers/store';
import suppliers from './buyers/suppliers';
import { browse } from './common/browse';
import { dropship } from './common/dropship';
import { marketHub } from './common/market-hub';
import privateCatalog from './common/privateCatalog';
import search from './common/search';
import tbng from './common/tbng';
import team from './common/team';
import { createEvent } from './klaviyo/createEvent';
import retailerManagement from './management/retailer';
import stripeHandler from './payment/StripeHandler';
import prismatic from './prismatic/prismatic';
import account from './sellers/account';
import sellerAnalytics from './sellers/analytics';
import collection from './sellers/collection';
import { customer } from './sellers/customer';
import inventory from './sellers/inventory';
import { brandMarketHub } from './sellers/market-hub';
import { notification } from './sellers/notification';
import { order as sellersOrder } from './sellers/order';
import persona from './sellers/persona';
import { profile } from './sellers/profile';
import proposals from './sellers/proposals';

interface Sellers {
  inventory: typeof inventory;
  collection: typeof collection;
  account: typeof account;
  profile: typeof profile;
  persona: typeof persona;
  customer: typeof customer;
  browse: typeof browse;
  notification: typeof notification;
  order: typeof sellersOrder;
  analytics: typeof sellerAnalytics;
  marketHub: typeof brandMarketHub;
  proposals: typeof proposals;
}

interface Buyers {
  retailerAccount: typeof retailerAccount;
  retailerProfile: typeof retailerProfile;
  sizeCurves: typeof sizeCurves;
  cart: typeof cart;
  storeAddress: typeof storeAddress;
  cms: typeof cms;
  order: typeof buyersOrder;
  orderForm: typeof orderForm;
  payment: typeof payment;
  recommendations: typeof recommendations;
  suppliers: typeof suppliers;
}

interface Admin {
  brandCompany: typeof brandCompany;
  retailerCompany: typeof retailerCompany;
  orders: typeof adminOrders;
  tags: typeof tags;
  collection: typeof adminCollection;
  paymentTypes: typeof paymentTypes;
  customers: typeof customers;
}

interface Common {
  team: typeof team;
  dropship: typeof dropship;
  privateCatalog: typeof privateCatalog;
  search: typeof search;
  tbng: typeof tbng;
  marketHub: typeof marketHub;
}

interface Payment {
  stripe: typeof stripeHandler;
}

interface Klaviyo {
  createEvent: typeof createEvent;
}

interface Management {
  retailer: typeof retailerManagement;
}

export type api = {
  sellers: Sellers;
  buyers: Buyers;
  admin: Admin;
  common: Common;
  prismatic: typeof prismatic;
  payment: Payment;
  klaviyo: Klaviyo;
  management: Management;
};

export const api: {
  sellers: Sellers;
  buyers: Buyers;
  admin: Admin;
  common: Common;
  prismatic: typeof prismatic;
  payment: Payment;
  klaviyo: Klaviyo;
  management: Management;
} = {
  sellers: {
    inventory,
    collection,
    account,
    profile,
    persona,
    customer,
    browse,
    notification,
    order: sellersOrder,
    analytics: sellerAnalytics,
    marketHub: brandMarketHub,
    proposals: proposals,
  },
  buyers: {
    retailerAccount,
    retailerProfile,
    sizeCurves,
    cart,
    storeAddress,
    cms,
    order: buyersOrder,
    orderForm,
    payment,
    recommendations,
    suppliers,
  },
  admin: {
    brandCompany,
    retailerCompany,
    orders: adminOrders,
    tags,
    collection: adminCollection,
    paymentTypes,
    customers,
  },
  common: {
    team,
    dropship,
    privateCatalog,
    search,
    tbng,
    marketHub,
  },
  prismatic,
  payment: {
    stripe: stripeHandler,
  },
  klaviyo: {
    createEvent,
  },
  management: {
    retailer: retailerManagement,
  },
};
