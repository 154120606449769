import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getPrivateCatalogRequestsForBrandSchema = z.object({
  totalCount: z.number(),
  requests: z.array(
    z.object({
      id: z.number(),
      createdAt: z.string(),
      retailerName: z.string(),
      status: z.object({
        name: z.string(),
        displayName: z.string(),
      }),
    })
  ),
});

const privateCatalog = {
  createPrivateCatalogRequest: {
    mutationKey: 'createPrivateCatalogRequest',
    mutation: async ({ brandId, retailerId }: { brandId: number; retailerId: number }) => {
      const response = await axiosAuth.post('/brand-access-request/create-request', {
        brandId,
        retailerId,
      });
      return response.data;
    },
  },
  createPrivateCatalogRequests: {
    mutationKey: 'createPrivateCatalogRequests',
    mutation: async ({ brandIds, retailerId }: { brandIds: number[]; retailerId: number }) => {
      const response = await axiosAuth.post('/brand-access-request/create-requests', {
        brandIds,
        retailerId,
      });
      return response.data;
    },
  },
  updatePrivateCatalogRequest: {
    mutationKey: 'updatePrivateCatalogRequest',
    mutation: async ({ requestId, status }: { requestId: number; status: string }) => {
      const response = await axiosAuth.post('/brand-access-request/update-request', {
        requestId,
        status,
      });
      return response.data;
    },
  },
  getPrivateCatalogRequestsForBrand: {
    schema: getPrivateCatalogRequestsForBrandSchema,
    queryKey: 'getPrivateCatalogRequestsForBrand',
    query: async ({
      brandId,
      status,
      take,
      skip,
    }: {
      brandId: number;
      status: string | undefined;
      take: number;
      skip: number;
    }) => {
      const response = await axiosAuth.get('/brand-access-request/brand/get-requests', {
        params: {
          brandId,
          status,
          take,
          skip,
        },
      });
      return getPrivateCatalogRequestsForBrandSchema.parse(response.data?.result);
    },
  },
};

export default privateCatalog;
