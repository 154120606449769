import z from 'zod';
import { axiosAuth } from '@libs/axios';

export const RetailerStoreSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    workingHours: z.string().nullable(),
    storeNumber: z.string().nullable(),
    contactName: z.string().nullable(),
    contactNumber: z.string().nullable(),
    isDefault: z.boolean().nullable(),
    address: z.object({
      line1: z.string().nullable(),
      line2: z.string().nullable(),
      city: z.string().nullable(),
      state: z.string().nullable(),
      country: z.string().nullable(),
      postcode: z.string().nullable(),
      remarks: z.string().nullable().optional(),
    }),
  })
);

const createRetailerStoreReturnSchema = z.object({
  id: z.number(),
  name: z.string(),
  workingHours: z.string().nullable(),
  contactName: z.string().nullable(),
  contactNumber: z.string().nullable(),
  storeNumber: z.string().nullable(),
  isDefault: z.boolean(),
});

export const storeAddress = {
  createRetailerStore: {
    schema: createRetailerStoreReturnSchema,
    mutation: async ({
      retailerId,
      name,
      workingHours,
      contactName,
      contactNumber,
      storeNumber,
      isDefault,
      address,
    }: {
      retailerId: number;
      name: string;
      workingHours?: string;
      contactName?: string;
      contactNumber?: string;
      storeNumber?: string;
      isDefault?: boolean;
      address: {
        line1: string;
        line2?: string;
        city: string;
        state: string;
        country: string;
        postcode: string;
        latitude?: number;
        longitude?: number;
        remarks?: string;
      };
    }) => {
      const response = await axiosAuth.post('/retailer/store/create-store', {
        retailerId,
        name,
        workingHours,
        contactName,
        contactNumber,
        storeNumber,
        isDefault,
        address,
      });

      return createRetailerStoreReturnSchema.parse(response.data?.result?.store);
    },
  },
  updateRetailerStore: {
    mutation: async (data: any) => {
      return await axiosAuth.post('retailer/store/update-store', {
        ...data,
      });
    },
  },
  getRetailerStores: {
    queryKey: 'get-retailer-stores',
    schema: RetailerStoreSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get('/retailer/store/get-stores', {
        params: {
          retailerId,
        },
      });

      return RetailerStoreSchema.parse(response.data?.result?.stores);
    },
  },
  deleteRetailerStore: {
    mutation: async (data: any) => {
      return await axiosAuth.post('retailer/store/delete-store', {
        ...data,
      });
    },
  },
};
