import z from 'zod';
import { axiosAuth } from '@libs/axios';

const getBrandCompanySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string(),
    abn: z.string().nullable().optional(),
    isVerified: z.boolean(),
  })
  .array();

const getBrandsSchema = z.object({
  total: z.number(),
  brands: z
    .array(
      z.object({
        id: z.number(),
        name: z.string().nullable(),
        companyId: z.number().nullable(),
        logoURL: z.string().nullable(),
        shipsFrom: z.string().nullable(),
        moq: z.number().nullable(),
        acceptsReturns: z.nullable(z.unknown()),
        productIdentifierType: z.nullable(z.unknown()),
        basedIn: z.nullable(z.unknown()),
        madeToOrder: z.nullable(z.unknown()),
        priceSegmentId: z.nullable(z.unknown()),
        createdAt: z.string().nullable(),
        updatedAt: z.string().nullable(),
        discount: z.number().nullable(),
        leadTime: z.number().nullable(),
        isPrivate: z.boolean().nullable(),
        company: z.object({
          id: z.number(),
          name: z.string().nullable(),
          abn: z.string().nullable().nullable().optional(),
          commissionRate: z.number(),
          isVerified: z.boolean().nullable(),
          createdAt: z.string().nullable(),
          updatedAt: z.string().nullable(),
        }),
        users: z.array(
          z.object({
            id: z.number(),
            email: z.string().nullable(),
            displayName: z.string().nullable(),
            title: z.string().nullable(),
            firstName: z.string().nullable(),
            lastName: z.string().nullable(),
            contactNumber: z.string().nullable(),
            roleId: z.number().nullable(),
            hasJoined: z.boolean().nullable(),
            createdAt: z.string().nullable(),
            updatedAt: z.string().nullable(),
            parentBrandCompanyId: z.number().nullable(),
            parentRetailerCompanyId: z.number().nullable(),
            auth0Id: z.string().nullable(),
            isDeleted: z.boolean().nullable(),
          })
        ),
      })
    )
    .optional(),
});

const brandCompany = {
  verifyBrandCompany: {
    mutationKey: 'verifyBrandCompany',
    mutation: async ({
      brandCompanyId,
      isVerified,
    }: {
      brandCompanyId: number;
      isVerified: boolean;
    }) => {
      const response = await axiosAuth.post('/admin/verify-brand-company', {
        brandCompanyId,
        isVerified,
      });
      return response;
    },
  },
  updatePrivateCatalogStatus: {
    mutationKey: 'updatePrivateCatalogStatus',
    mutation: async ({ brandId, isPrivate }: { brandId: number; isPrivate: boolean }) => {
      const response = await axiosAuth.post('/admin/update-brand-private-catalog-status', {
        brandId,
        isPrivate,
      });
      return response;
    },
  },
  getAllBrandCompany: {
    queryKey: 'get-brand-company',
    schema: getBrandCompanySchema,
    query: async ({ take, skip }: { take: number; skip: number }) => {
      const response = await axiosAuth.get(`admin/get-brand-company?take=${take}&skip=${skip}`);
      return getBrandCompanySchema.parse(response.data);
    },
  },
  getAllBrands: {
    queryKey: 'get-brands',
    schema: getBrandsSchema,
    query: async ({
      take,
      skip,
      searchTerm,
    }: {
      take: number;
      skip: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get(
        `admin/get-brands?take=${take}&skip=${skip}&searchTerm=${searchTerm}`
      );
      return getBrandsSchema.parse(response.data);
    },
  },
  uploadInventory: {
    mutation: async (formData: FormData) => {
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(percentCompleted);
        },
      };
      const response = await axiosAuth.post('/inventory/upload-refined-inventory', formData, {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response;
    },
  },
  updateCommission: {
    mutationKey: 'updateBrandCompanyCommission',
    mutation: async ({
      brandCompanyId,
      commissionRate,
    }: {
      brandCompanyId: number;
      commissionRate: number;
    }) => {
      const response = await axiosAuth.post('/admin/update-commission-rate', {
        brandCompanyId,
        commissionRate,
      });
      return response;
    },
  },
};

export type brandCompany = typeof brandCompany;

export default brandCompany;
