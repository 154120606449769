import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

export const getSizeCurvesReturnSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    ProductType: z.object({
      id: z.number(),
      name: z.string(),
    }),
    retailer: z.object({
      id: z.number(),
      name: z.string(),
    }),
    brand: z.object({
      id: z.number(),
      name: z.string(),
    }),
  })
);

const SizeCurvePercentages = z.array(
  z.object({
    size: z.string(),
    percentage: z.number(),
  })
);

const getSizeCurvesWithPercentagesReturnSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    ProductType: z.object({
      id: z.number(),
      name: z.string(),
    }),
    retailer: z.object({
      id: z.number(),
      name: z.string(),
    }),
    brand: z.object({
      id: z.number(),
      name: z.string(),
    }),
    SizeCurvePercentages,
  })
);

const getSizeCurveReturnSchema = z.object({
  id: z.number(),
  name: z.string(),
  retailer: z.object({
    id: z.number(),
    name: z.string(),
  }),
  brand: z.object({
    id: z.number(),
    name: z.string(),
  }),
  ProductType: z.object({
    id: z.number(),
    name: z.string(),
  }),
  SizeCurvePercentages,
});

const createSizeCurveReturnSchema = z.object({
  id: z.number(),
  name: z.string(),
  brandId: z.number(),
  retailerId: z.number(),
  productTypeId: z.number(),
});

export const sizeCurves = {
  getSizeCurves: {
    queryKey: 'getSizeCurves',
    schema: getSizeCurvesReturnSchema,
    query: async ({
      retailerId,
      brandId,
      productType,
    }: {
      retailerId: number;
      brandId?: number;
      productType?: string;
    }) => {
      const response = await axiosAuth.get('/size-curve/get-size-curves', {
        params: {
          retailerId,
          ...(brandId && { brandId }),
          ...(productType && { productType }),
        },
      });

      return getSizeCurvesReturnSchema.parse(response.data);
    },
  },
  getSizeCurvesWithPercentages: {
    queryKey: 'getSizeCurvesWithPercentages',
    schema: getSizeCurvesWithPercentagesReturnSchema,
    query: async ({
      retailerId,
      brandId,
      productType,
    }: {
      retailerId: number;
      brandId?: number;
      productType?: string;
    }) => {
      const response = await axiosAuth.get('/size-curve/get-size-curves-with-percentages', {
        params: {
          retailerId,
          ...(brandId && { brandId }),
          ...(productType && { productType }),
        },
      });

      // return response.data;
      return getSizeCurvesWithPercentagesReturnSchema.parse(response.data);
    },
  },
  getSizeCurve: {
    queryKey: 'getSizeCurve',
    schema: getSizeCurveReturnSchema,
    query: async ({ sizeCurveId }: { sizeCurveId: number }) => {
      const response = await axiosAuth.get('/size-curve/get-size-curve', {
        params: {
          sizeCurveId,
        },
      });

      return getSizeCurveReturnSchema.parse(response.data);
    },
  },
  createSizeCurve: {
    mutation: async ({
      name,
      retailerId,
      brandId,
      productType,
      sizeCurve,
    }: {
      name: string;
      retailerId: number;
      brandId: number;
      productType: string | undefined;
      sizeCurve: {
        size: string;
        percentage: number;
      }[];
    }) => {
      const response = await axiosAuth.post('/size-curve/create-size-curve', {
        name,
        retailerId,
        brandId,
        productType,
        sizeCurve,
      });

      return createSizeCurveReturnSchema.parse(response.data);
    },
  },
};
