import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getCollectionsSchema = z.object({
  total: z.number(),
  collections: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      isPublished: z.boolean(),
      isDeleted: z.boolean(),
      brand: z.object({
        id: z.number(),
        name: z.string(),
      }),
      deliveryStartDate: z.string().datetime().nullable(),
      deliveryEndDate: z.string().datetime().nullable(),
      orderStartDate: z.string().datetime().nullable(),
      orderEndDate: z.string().datetime().nullable(),
      hasOrderWindow: z.boolean(),
      description: z.string(),
      type: z.string(),
      additionalDiscount: z.number(),
      isPrivate: z.boolean(),
    })
  ),
});

const collection = {
  getCollections: {
    queryKey: 'getCollections',
    schema: getCollectionsSchema,
    query: async ({
      skip,
      take,
      searchTerm,
    }: {
      skip: number;
      take: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get('/admin/get-collections', {
        params: {
          skip,
          take,
          searchTerm,
        },
      });
      return getCollectionsSchema.parse(response.data);
    },
  },
};

export default collection;
