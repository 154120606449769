import { axiosAuth } from '@libs/axios';

const team = {
  inviteSellerTeamMember: {
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/team/invite-seller-team-member', {
        ...data,
      });

      return response;
    },
  },

  inviteBuyerTeamMember: {
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/team/invite-buyer-team-member', {
        ...data,
      });

      return response;
    },
  },

  removeBuyerTeamMember: {
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/team/delete-buyer-team-member', {
        ...data,
      });

      return response;
    },
  },

  removeSellerTeamMember: {
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/team/delete-seller-team-member', {
        ...data,
      });

      return response;
    },
  },
  sendPreLoginRequest: {
    mutationKey: 'sendPreLoginRequest',
    mutation: async ({
      retailerName,
      firstName,
      lastName,
      email,
      contactNumber,
      preLoginUrl,
      userCategories = [],
      website,
      storeSize,
      bio,
    }: {
      retailerName?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      contactNumber?: string;
      preLoginUrl: string;
      userCategories?: string[];
      website?: string;
      storeSize?: string;
      bio?: string;
    }) => {
      const response = await axiosAuth.post('/management/retailer/send-pre-login-request', {
        retailerName,
        firstName,
        lastName,
        email,
        preLoginUrl,
        ...(contactNumber && contactNumber?.length > 0 && { contactNumber }),
        ...(userCategories?.length > 0 && {
          userCategories: userCategories,
        }),
        ...(website ? { website } : {}),
        ...(storeSize ? { storeSize } : {}),
        ...(bio ? { bio } : {}),
      });
      return response.data;
    },
  },
};

export default team;
