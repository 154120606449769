import { z } from 'zod';
import { axiosAuth } from '@libs/axios';
import { ProductVariantSchema } from '@api/schema';
import { BrandOrderStatus } from '@constants';

const createDraftOrderReturnSchema = z.object({
  id: z.number(),
  retailerId: z.number(),
  createdByUserId: z.number(),
  isDraft: z.boolean(),
  paymentTypeId: z.number().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const validPaymentTypesArray = [
  'credit_debit_card',
  'net_banking',
  'frontline',
  'arl',
  'bank_transfer',
  'zip_pay',
  'intersport',
  'invoice',
] as const;

export type validPaymentTypes = (typeof validPaymentTypesArray)[number];

export type CreateDraftOrderPayloadType = {
  retailerId: number;
  cart: {
    sku: string;
    requestedFor: string; // datetime string
    quantity: number;
    appliedCollectionId?: number;
    storeId: number;
  }[];
  stores: { storeId: number; notes: string }[];
};

const getBuyerOrdersReturnSchema = z.object({
  orderCount: z.number(),
  orders: z.array(
    z.object({
      id: z.number(),
      placedOn: z.string(),
      paymentType: z.string().nullable(),
      retailerName: z.string(),
      subtotal: z.number(),
      gstAmount: z.number(),
      gstPercentage: z.number(),
      total: z.number(),
      buyerPaymentStatus: z.object({
        id: z.number(),
        name: z.string(),
        displayName: z.string(),
      }),
      orderSource: z.enum(['Seller', 'Cart', 'Order Form']),
      brandOrders: z.array(
        z.object({
          id: z.number(),
          displayId: z.string(),
          brandName: z.string(),
          requestedFor: z.string(),
          placedOn: z.string(),
          shipmentCarrier: z.string().nullable(),
          shipDate: z.string().nullable(),
          deliveryDate: z.string().nullable(),
          hasBuyerInvoice: z.boolean(),
          hasSellerBill: z.boolean(),
          hasSellerPurchaseOrder: z.boolean(),
          subtotal: z.number(),
          status: z.object({
            id: z.number(),
            name: z.string(),
            displayName: z.string(),
          }),
          deliveryStatus: z
            .object({
              id: z.number(),
              name: z.string(),
              displayName: z.string(),
            })
            .nullable(),
          notes: z.string().nullable(),
        })
      ),
    })
  ),
});

const getOrderStatsReturnSchema = z.object({
  stats: z.object({
    actionsRequired: z.number(),
    requestingApprovalCount: z.number(),
    requestingApprovalAmount: z.number(),
    openOrdersCount: z.number(),
    openOrdersAmount: z.number(),
    completedOrdersCount: z.number(),
    completedOrdersAmount: z.number(),
  }),
});
export const orderProducts = z.array(
  z.object({
    itemId: z.string(),
    name: z.string(),
    primaryAttributeName: z.string().optional().nullable(),
    secondaryAttributeName: z.string(),
    topics: z.array(z.string()),
    commonAttributes: z.array(
      z.object({
        name: z.string(),
        value: z.string(),
      })
    ),
    variant: z.object({
      attribute: z.string().nullable(),
      images: z.array(
        z.object({
          url: z.string().url(),
        })
      ),
      variants: z.array(
        z.object({
          sku: z.string(),
          vendorSku: z.string(),
          price: z.number(),
          stock: z.number(),
          attribute: z.string(),
          isDefault: z.boolean(),
        })
      ),
    }),
    siblingVariantAttributes: z.array(z.string()),
    brand: z.object({
      id: z.number(),
      name: z.string(),
      globalMOQ: z.number().nullable(),
      globalDiscount: z.number().nullable(),
      leadTime: z.number().nullable(),
    }),
    moq: z.number().nullable(),
    discount: z.number().nullable(),
    additionalDiscount: z.number().nullable(),
    logoURL: z.string().url().nullable(),
    persona: z
      .object({
        id: z.number(),
        moq: z.number(),
        discount: z.number(),
        additionalDiscount: z.number(),
      })
      .optional(),
  })
);
export const getOrderedProductsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(ProductVariantSchema),
});

const imageSchema = z.object({
  url: z.string(),
});

const secondaryVariantSchema = z.object({
  attribute: z.string(),
  sku: z.string(),
  vendorSku: z.string(),
  subTotal: z.number(),
  price: z.number(),
  salePrice: z.number().nullable(),
  qty: z.number(),
  proposedQty: z.number().nullable(),
  newQty: z.number().nullable(),
  appliedCollectionId: z.number().nullable(),
  appliedCollectionDiscount: z.number().nullable(),
  appliedPersonaId: z.number().nullable(),
  appliedDiscount: z.number().nullable(),
  appliedAdditionalDiscount: z.number().nullable(),
});

const primaryVariantSchema = z.object({
  attribute: z.string().nullable(),
  images: z.array(imageSchema).nullable(),
  secondaryVariants: z.array(secondaryVariantSchema),
});

const cartItemSchema = z.object({
  itemId: z.string(),
  name: z.string(),
  primaryVariantAttribute: z.string().nullable(),
  secondaryVariantAttribute: z.string().optional(),
  primaryVariants: z.array(primaryVariantSchema),
});

const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  postCode: z.string(),
});

const brandOrderSchema = z.object({
  id: z.number(),
  displayId: z.string(),
  brandName: z.string(),
  requestedFor: z.string().datetime(),
  placedOn: z.string().datetime().nullable(),
  shipmentCarrier: z.string().nullable(),
  shipDate: z.string().datetime().nullable(),
  deliveryDate: z.string().datetime().nullable(),
  deliveryTrackingId: z.string().nullable(),
  subtotal: z.number(),
  gstPercentage: z.number(),
  gstAmount: z.number(),
  total: z.number(),
  status: z
    .object({
      id: z.number(),
      name: z.nativeEnum(BrandOrderStatus),
      displayName: z.string(),
    })
    .nullable(),
  deliveryStatus: z
    .object({
      id: z.number(),
      name: z.string(),
      displayName: z.string(),
    })
    .nullable(),
  buyerPaymentStatus: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  hasBuyerInvoice: z.boolean(),
  hasSellerBill: z.boolean(),
  hasSellerPurchaseOrder: z.boolean(),
  retailerName: z.string(),
  retailerCompanyName: z.string(),
  retailerStore: z
    .object({
      id: z.number(),
      name: z.string(),
      contactName: z.string().nullable(),
      contactNumber: z.string().nullable(),
      address: addressSchema,
      remarks: z.string().nullable(),
    })
    .optional(),
  cart: z.array(cartItemSchema),
  notes: z.string().nullable().optional(),
});

const getBuyerBrandOrderReturnSchema = z.object({
  brandOrder: brandOrderSchema,
});

const getBuyerOrderReturnSchema = z.object({
  orderId: z.number(),
  brandOrders: z.array(brandOrderSchema),
});

const addPaymentDetailsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(201),
  message: z.literal('Success'),
  result: z.object({
    orderId: z.number(),
  }),
});

//TODO: we need to add suitable zod schema for getOrderInvoiceSchema with response type: blob
const getOrderInvoiceSchema = z.any();

const order = {
  createDraftOrder: {
    schema: createDraftOrderReturnSchema,
    mutation: async ({ retailerId, cart, stores }: CreateDraftOrderPayloadType) => {
      const response = await axiosAuth.post('/order/create-draft-order', {
        retailerId,
        cart,
        stores,
      });

      return createDraftOrderReturnSchema.parse(response.data);
    },
  },
  addDeliveryStore: {
    mutation: async ({
      orderId,
      storeId,
      notes,
    }: {
      orderId: number;
      storeId: number;
      notes: string;
    }) => {
      const response = await axiosAuth.post('/order/add-delivery-store', {
        orderId,
        storeId,
        notes,
      });

      return response.data;
    },
  },
  addPaymentDetails: {
    schema: addPaymentDetailsReturnSchema,
    mutation: async ({
      orderId,
      paymentType,
    }: {
      orderId: number;
      paymentType: validPaymentTypes;
    }) => {
      const response = await axiosAuth.post('/order/add-payment-details', {
        orderId,
        paymentType,
      });

      return addPaymentDetailsReturnSchema.parse(response.data);
    },
  },
  getBuyerOrders: {
    queryKey: 'getBuyerOrders',
    schema: getBuyerOrdersReturnSchema,
    query: async ({
      retailerId,
      skip,
      take,
      type,
      searchTerm,
    }: {
      retailerId: number;
      take: number;
      skip: number;
      type: 'action_required' | 'open' | 'all' | 'proposals';
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get('/order/buyer/get-orders', {
        params: {
          retailerId,
          take,
          skip,
          ...(type !== 'all' && { type }),
          ...(searchTerm && { searchTerm }),
        },
      });

      return getBuyerOrdersReturnSchema.parse(response.data);
    },
  },
  getOrderStats: {
    queryKey: 'getOrderStats',
    schema: getOrderStatsReturnSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get('/order/buyer/get-order-stats', {
        params: {
          retailerId,
        },
      });

      return getOrderStatsReturnSchema.parse(response.data);
    },
  },
  getOrderedProducts: {
    queryKey: 'getOrderedProducts',
    schema: getOrderedProductsSchema,
    query: async ({
      retailerId,
      searchTerm,
      count,
      type,
      after,
    }: {
      retailerId: number;
      count: number;
      searchTerm?: string;
      type?: 'open' | 'action_required' | 'completed';
      after?: number;
    }) => {
      const response = await axiosAuth.get('/order/buyer/get-ordered-products', {
        params: {
          retailerId: retailerId,
          count,
          ...(searchTerm && { searchTerm }),
          ...(type && { type }),
          ...(after && { after }),
        },
      });
      return getOrderedProductsSchema.parse(response.data?.result);
    },
  },
  getBuyerBrandOrder: {
    queryKey: 'getBuyerBrandOrder',
    schema: getBuyerBrandOrderReturnSchema,
    query: async ({ brandOrderId }: { brandOrderId: number }) => {
      const response = await axiosAuth.get('/order/buyer/get-brand-order', {
        params: {
          brandOrderId,
        },
      });

      return getBuyerBrandOrderReturnSchema.parse(response.data);
    },
  },
  getBuyerOrder: {
    queryKey: 'getBuyerOrder',
    schema: getBuyerOrderReturnSchema,
    query: async ({ orderId }: { orderId: number }) => {
      const response = await axiosAuth.get('/order/buyer/get-order', {
        params: {
          orderId,
        },
      });

      return getBuyerOrderReturnSchema.parse(response.data);
    },
  },
  getDraftBuyerOrder: {
    queryKey: 'getDraftBuyerOrder',
    schema: getBuyerOrderReturnSchema,
    query: async ({ orderId }: { orderId: number }) => {
      const response = await axiosAuth.get('/order/buyer/get-draft-order', {
        params: {
          orderId,
        },
      });

      return getBuyerOrderReturnSchema.parse(response.data);
    },
  },
  approveOrder: {
    mutation: async ({ brandOrderId }: { brandOrderId: number }) => {
      const response = await axiosAuth.post('/order/buyer/approve-amended-order', {
        brandOrderId,
      });

      return response.data;
    },
  },
  amendOrder: {
    // schema: getBuyerBrandOrderReturnSchema,
    mutation: async ({
      brandOrderId,
      cart,
    }: {
      brandOrderId: number;
      cart: {
        sku: string;
        quantity: number;
      }[];
    }) => {
      const response = await axiosAuth.post('/order/buyer/amend-order', {
        brandOrderId,
        cart,
      });

      return response.data;
      // return getBuyerBrandOrderReturnSchema.parse(response.data);
    },
  },
  approveOrderProposal: {
    mutation: async ({ orderId }: { orderId: number }) => {
      const response = await axiosAuth.post('/order/buyer/update-order-state', {
        orderId,
        state: 'proposal_approved',
      });
      return response.data;
    },
  },
  rejectOrderProposal: {
    mutation: async ({ orderId }: { orderId: number }) => {
      const response = await axiosAuth.post('/order/buyer/update-order-state', {
        orderId,
        state: 'proposal_rejected',
      });
      return response.data;
    },
  },
  exportCSV: {
    mutationKey: 'export-CSV-orders-buyers',
    schema: z.string(),
    mutation: async ({
      retailerId,
      type,
    }: {
      retailerId: number;
      type: 'action_required' | 'open' | 'all' | 'proposals';
    }) => {
      const response = await axiosAuth.get('/order/buyer/export-csv', {
        params: {
          retailerId,
          ...(type !== 'all' && { type }),
        },
      });

      return z.string().parse(response.data);
    },
  },
  getOrderInvoice: {
    mutationKey: 'getOrderInvoice',
    schema: getOrderInvoiceSchema,
    mutation: async ({ brandOrderId }: { brandOrderId: number }) => {
      const response = await axiosAuth.get('/invoice/buyer/invoice', {
        params: {
          brandOrderId,
        },
        responseType: 'blob',
      });

      return getOrderInvoiceSchema.parse(response.data);
    },
  },
};

export type order = typeof order;

export default order;
