import z from 'zod';
import { axiosAuth } from '@libs/axios';

export const getRetailerSchema = z.object({
  total: z.number(),
  retailers: z
    .array(
      z.object({
        id: z.number(),
        name: z.string().nullable(),
        companyId: z.number().nullable(),
        logoURL: z.string().nullable(),
        createdAt: z.string().nullable(),
        updatedAt: z.string().nullable(),
        company: z.object({
          id: z.number(),
          name: z.string().nullable(),
          abn: z.string().nullable().optional(),
          isVerified: z.boolean().nullable(),
          isDropshipEligible: z.boolean().optional(),
          createdAt: z.string().nullable(),
          updatedAt: z.string().nullable(),
        }),
        users: z.array(
          z.object({
            id: z.number(),
            email: z.string().nullable(),
            displayName: z.string().nullable(),
            title: z.string().nullable(),
            firstName: z.string().nullable(),
            lastName: z.string().nullable(),
            contactNumber: z.string().nullable(),
            roleId: z.number().nullable(),
            hasJoined: z.boolean().nullable(),
            createdAt: z.string().nullable(),
            updatedAt: z.string().nullable(),
            parentRetailerCompanyId: z.number().nullable(),
            parentBrandCompanyId: z.number().nullable(),
            auth0Id: z.string().nullable(),
            isDeleted: z.boolean(),
          })
        ),
      })
    )
    .optional(),
});

export const retailerCompany = {
  verifyRetailerCompany: {
    mutationKey: 'verifyRetailerCompany',
    mutation: async ({
      retailerCompanyId,
      isVerified,
    }: {
      retailerCompanyId: number;
      isVerified: boolean;
    }) => {
      const response = await axiosAuth.post('/admin/verify-retailer-company', {
        retailerCompanyId,
        isVerified,
      });
      return response;
    },
  },
  updateDropshipStatus: {
    mutationKey: 'updateDropshipStatus',
    mutation: async ({
      retailerCompanyId,
      isDropshipEligible,
    }: {
      retailerCompanyId: number;
      isDropshipEligible: boolean;
    }) => {
      const response = await axiosAuth.post('/admin/update-dropship-eligibility', {
        retailerCompanyId,
        isDropshipEligible,
      });
      return response;
    },
  },
  getAllRetailers: {
    queryKey: 'get-retailers',
    schema: getRetailerSchema,
    query: async ({
      take,
      skip,
      searchTerm,
    }: {
      take: number;
      skip: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get(
        `/admin/get-retailers?take=${take}&skip=${skip}&searchTerm=${searchTerm}`
      );
      return getRetailerSchema.parse(response.data);
    },
  },
};
