import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getAdminOrdersSchema = z.object({
  orderCount: z.number(),
  orders: z.array(
    z.object({
      id: z.number(),
      placedOn: z.string(),
      paymentType: z.string().nullable(),
      retailerName: z.string(),
      subtotal: z.number(),
      buyerPaymentStatus: z.object({
        id: z.number(),
        name: z.string(),
        displayName: z.string(),
      }),
      orderSource: z.enum(['Seller', 'Cart', 'Order Form']),
      brandOrders: z.array(
        z.object({
          id: z.number(),
          displayId: z.string(),
          brandName: z.string(),
          requestedFor: z.string(),
          placedOn: z.string(),
          shipmentCarrier: z.string().nullable(),
          shipDate: z.string().nullable(),
          deliveryDate: z.string().nullable(),
          hasBuyerInvoice: z.boolean(),
          hasSellerBill: z.boolean(),
          hasSellerPurchaseOrder: z.boolean(),
          subtotal: z.number(),
          notes: z.string().nullable(),
          status: z.object({
            id: z.number(),
            name: z.string(),
            displayName: z.string(),
          }),
          deliveryStatus: z
            .object({
              id: z.number(),
              name: z.string(),
              displayName: z.string(),
            })
            .nullable(),
          sellerPaymentStatus: z.object({
            id: z.number(),
            name: z.string(),
            displayName: z.string(),
          }),
        })
      ),
    })
  ),
});

const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  postCode: z.string(),
});

export const getAdminOrderSchema = z.object({
  id: z.number(),
  displayId: z.string(),
  brandName: z.string(),
  requestedFor: z.string(),
  placedOn: z.string(),
  shipmentCarrier: z.string().nullable(),
  shipDate: z.string().nullable(),
  deliveryDate: z.string().nullable(),
  deliveryTrackingId: z.string().nullable(),
  subtotal: z.number(),
  status: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  deliveryStatus: z
    .object({
      id: z.number(),
      name: z.string(),
      displayName: z.string(),
    })
    .nullable(),
  sellerPaymentStatus: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  buyerPaymentStatus: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  hasBuyerInvoice: z.boolean(),
  hasSellerBill: z.boolean(),
  hasSellerPurchaseOrder: z.boolean(),
  retailerName: z.string(),
  retailerCompanyName: z.string(),
  retailerStore: z
    .object({
      id: z.number(),
      name: z.string(),
      contactName: z.string().nullable(),
      contactNumber: z.string().nullable(),
      remarks: z.string().nullable(),
      address: addressSchema,
    })
    .optional(),
  cart: z.array(
    z.object({
      itemId: z.string(),
      name: z.string(),
      primaryVariantAttribute: z.string().nullable(),
      secondaryVariantAttribute: z.string().optional(),
      primaryVariants: z.array(
        z.object({
          attribute: z.string().nullable(),
          images: z
            .array(
              z.object({
                url: z.string().url(),
              })
            )
            .nullable(),
          secondaryVariants: z.array(
            z.object({
              attribute: z.string(),
              sku: z.string(),
              vendorSku: z.string(),
              subTotal: z.number(),
              price: z.number(),
              salePrice: z.number().nullable(),
              qty: z.number(),
              proposedQty: z.number().nullable(),
              newQty: z.number().nullable(),
              appliedCollectionId: z.number().nullable(),
              appliedCollectionDiscount: z.number().nullable(),
              appliedPersonaId: z.number().nullable(),
              appliedDiscount: z.number().nullable(),
              appliedAdditionalDiscount: z.number().nullable(),
            })
          ),
        })
      ),
    })
  ),
  notes: z.string().nullable().optional(),
});

const orders = {
  getAdminOrders: {
    queryKey: 'getAdminOrdersSchema',
    schema: getAdminOrdersSchema,
    query: async ({
      type,
      skip,
      take,
      searchTerm,
    }: {
      type?: string;
      skip: number;
      take: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get(`/order/admin/get-orders?take=${take}&skip=${skip}`, {
        params: {
          ...(type && { type }),
          ...(searchTerm && { searchTerm }),
        },
      });
      return getAdminOrdersSchema.parse(response.data);
    },
  },
  getAdminOrder: {
    queryKey: 'getAdminOrder',
    schema: getAdminOrderSchema,
    query: async (orderId: number) => {
      const response = await axiosAuth.get(`/order/admin/get-order?brandOrderId=${orderId}`);
      return getAdminOrderSchema.parse(response.data?.brandOrder);
    },
  },
  updateOrderStatus: {
    mutationKey: 'updateOrderStatus',
    mutation: async ({
      brandOrderId,
      status,
      reason,
    }: {
      brandOrderId: number;
      status: string;
      reason?: string;
    }) => {
      const response = await axiosAuth.post('/order/admin/update-status', {
        brandOrderId,
        status,
        ...(reason && { reason }),
      });
      return response;
    },
  },
  updateDeliveryStatus: {
    mutationKey: 'updateDeliveryStatus',
    mutation: async ({ brandOrderId, status }: { brandOrderId: number; status: string }) => {
      const response = await axiosAuth.post('/order/admin/update-delivery-status', {
        brandOrderId,
        status,
      });
      return response;
    },
  },
  updateRetailerPaymentStatus: {
    mutationKey: 'updateRetailerPaymentStatus',
    mutation: async ({ orderId, status }: { orderId: number; status: string }) => {
      const response = await axiosAuth.post('/payment/admin/update-buyer-payment-status', {
        orderId,
        status,
      });
      return response;
    },
  },
  updateSellerPaymentStatus: {
    mutationKey: 'updateSellerPaymentStatus',
    mutation: async ({ brandOrderId, status }: { brandOrderId: number; status: string }) => {
      const response = await axiosAuth.post('/payment/admin/update-seller-payment-status', {
        brandOrderId,
        status,
      });
      return response;
    },
  },
};

export type order = typeof orders;

export default orders;
