import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getAdminProductTags = z.object({
  total: z.number(),
  tags: z.array(
    z.object({
      id: z.number(),
      displayName: z.string(),
      isActive: z.boolean(),
      createdBy: z
        .object({
          id: z.number(),
          email: z.string(),
          firstName: z.string(),
          lastName: z.string(),
        })
        .nullable(),
      brand: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .nullable(),
      createdAt: z.string(),
    })
  ),
});

const createAdminTagOutputSchema = z.object({
  id: z.number(),
  path: z.string(),
  displayName: z.string(),
});

const tags = {
  getProductTags: {
    queryKey: 'getAdminProductTags',
    schema: getAdminProductTags,
    query: async ({
      status,
      skip,
      take,
      searchTerm,
    }: {
      status: string;
      skip: number;
      take: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get('/admin/get-product-tags', {
        params: {
          status,
          skip,
          take,
          searchTerm,
        },
      });

      return getAdminProductTags.parse(response.data);
    },
  },

  createProductTag: {
    mutation: async ({ displayName }: { displayName: string }) => {
      const response = await axiosAuth.post(`/admin/create-product-tag`, {
        displayName,
      });
      return createAdminTagOutputSchema.parse(response.data.tag);
    },
  },

  deleteProductTag: {
    mutation: async ({ id }: { id: string }) => {
      const response = await axiosAuth.post(`/admin/tag`, {
        id,
      });
      return response.data;
    },
  },
};

export default tags;
