import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const retailer = {
  checkCustomerForBrand: {
    queryKey: 'checkCustomerForBrand' as const,
    schema: z.object({
      isCustomer: z.boolean(),
    }),
    query: async ({ retailerId, brandId }: { retailerId: number; brandId: number }) => {
      const response = await axiosAuth.get('/management/retailer/check-customer-for-brand', {
        params: {
          retailerId,
          brandId,
        },
      });
      return retailer.checkCustomerForBrand.schema.parse(response.data?.result);
    },
  },
};

export type retailer = typeof retailer;

export default retailer;
