import { loadStripe } from '@stripe/stripe-js';
import z from 'zod';
import { axiosAuth } from '@libs/axios';

const stripePublicKey = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!;
const stripePromise = loadStripe(stripePublicKey);

export const StripeSessionSchema = z.object({
  sessionId: z.string(),
});

export const CustomerCardSchema = z.object({
  id: z.string(),
  last4: z.string(),
  brand: z.string(),
  exp_month: z.number(),
  exp_year: z.number(),
});

export const CustomerCardsReturnSchema = z.object({
  cards: z.array(CustomerCardSchema),
});

const PayWithSavedCardReturnSchema = z.literal('Success');

export const CreateSetupSessionInputSchema = z.object({
  orderId: z.number(),
});

export const RedirectToCheckoutInputSchema = z.object({
  sessionId: z.string(),
});

export const InitiatePaymentIntentInputSchema = z.object({
  paymentMethodId: z.string(),
  orderId: z.number(),
});

export const CreateNetbankingSetupSessionInputSchema = z.object({
  orderId: z.number(),
});

export const NetBankingAccountSchema = z.object({
  id: z.string(),
  last4: z.string(),
  bsb_number: z.string(),
});

export const NetBankingAccountsResponseSchema = z.object({
  netBankingAccounts: z.array(NetBankingAccountSchema),
});

export const InitiateNetbankingPaymentInputSchema = z.object({
  paymentMethodId: z.string(),
  orderId: z.number(),
});

export const getOrderTotalSchema = z.object({
  orderId: z.number(),
});

const DeletePaymentMethodReturnSchema = z.object({
  status: z.literal(200),
  message: z.string(),
  data: z.object({
    email: z.string().email(),
    paymentMethodId: z.string(),
  }),
});

const stripeHandler = {
  getCustomerCards: {
    queryKey: 'getCustomerCards',
    schema: CustomerCardsReturnSchema,
    query: async () => {
      const response = await axiosAuth.get('/payment/get-cards');

      return CustomerCardsReturnSchema.parse(response.data);
    },
  },
  stripeRedirect: {
    schema: z.object({
      success: z.literal(true),
    }),
    mutation: async ({ orderId }: { orderId: number }) => {
      const stripeSessionResponse = await axiosAuth.post('/payment/setup-session', {
        orderId,
      });

      const { sessionId } = StripeSessionSchema.parse(stripeSessionResponse.data);

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('stripe promise failed');
      }

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      return { success: true };
    },
  },
  payWithSavedCard: {
    schema: PayWithSavedCardReturnSchema,
    mutation: async ({
      orderId,
      paymentMethodId,
    }: {
      orderId: number;
      paymentMethodId: string;
    }) => {
      const response = await axiosAuth.post('/payment/create-payment-intent-with-saved-card', {
        orderId,
        paymentMethodId,
      });
      return PayWithSavedCardReturnSchema.parse(response.data);
    },
  },

  deletePaymentMethod: {
    schema: DeletePaymentMethodReturnSchema,
    mutation: async ({ paymentMethodId }: { paymentMethodId: string }) => {
      const response = await axiosAuth.post('/payment/delete-payment-method', {
        paymentMethodId,
      });

      return DeletePaymentMethodReturnSchema.parse(response.data);
    },
  },

  getNetBankingAccounts: {
    queryKey: 'getNetBankingAccounts',
    schema: NetBankingAccountsResponseSchema,
    query: async () => {
      const response = await axiosAuth.get('/payment/get-net-banking-infos');

      return NetBankingAccountsResponseSchema.parse(response.data);
    },
  },
  stripeNetBankingRedirect: {
    schema: z.object({
      success: z.literal(true),
    }),
    mutation: async ({ orderId }: { orderId: number }) => {
      const stripeSessionResponse = await axiosAuth.post('/payment/setup-session-netbanking', {
        orderId,
      });

      const { sessionId } = StripeSessionSchema.parse(stripeSessionResponse.data);

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('stripe promise failed');
      }

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      return { success: true };
    },
  },
  payWithSavedNetBankingAccount: {
    schema: PayWithSavedCardReturnSchema,
    mutation: async ({
      orderId,
      paymentMethodId,
    }: {
      orderId: number;
      paymentMethodId: string;
    }) => {
      const response = await axiosAuth.post('/payment/initiate-payment-netbanking', {
        orderId,
        paymentMethodId,
      });
      return PayWithSavedCardReturnSchema.parse(response.data);
    },
  },
};

export default stripeHandler;
