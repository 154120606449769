import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

export enum SORT_BY {
  OLDEST_FIRST = 'OLDEST_FIRST',
  LATEST_FIRST = 'LATEST_FIRST',
}

const getInvitedCustomersSchema = z.object({
  total: z.number(),
  users: z.array(
    z.object({
      id: z.number(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string().email(),
      parentBrand: z.string().nullable(),
      parentRetailer: z.string(),
      createdAt: z.string().datetime(),
      invitedBy: z
        .object({
          email: z.string(),
          userType: z.string(),
          parentCompany: z.string().nullable(),
        })
        .nullable(),
    })
  ),
});

export type GetInvitedCustomersSchemaType = z.infer<typeof getInvitedCustomersSchema>;
export type InvitedUserObjectType = GetInvitedCustomersSchemaType['users'][0];

export const customers = {
  getInvitedCustomers: {
    queryKey: 'getInvitedCustomers',
    schema: getInvitedCustomersSchema,
    query: async ({
      skip,
      take,
      searchTerm,
      sortBy,
      userType = undefined,
    }: {
      skip: number;
      take: number;
      searchTerm?: string;
      sortBy: SORT_BY;
      userType?: 'brand' | 'retailer' | 'andisor';
    }): Promise<z.infer<typeof getInvitedCustomersSchema>> => {
      const response = await axiosAuth.get('/admin/get-invited-users', {
        params: {
          skip,
          take,
          searchTerm,
          sortBy,
          userType,
        },
      });
      return response.data.result;
    },
  },
};
