import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getCustomerPaymentDetailsSchema = z.object({
  cards: z
    .array(
      z.object({
        id: z.string(),
        last4: z.string(),
        brand: z.string(),
        exp_month: z.number(),
        exp_year: z.number(),
      })
    )
    .nullable(),
  netBankingAccounts: z
    .array(
      z.object({
        id: z.string(),
        last4: z.string(),
        bsb_number: z.string(),
      })
    )
    .nullable(),
  buyerGroups: z.object({
    frontline: z
      .object({
        membershipNumber: z.number(),
        buyerGroupId: z.number(),
        buyerGroupName: z.string(),
      })
      .nullable(),
    arl: z
      .object({
        membershipNumber: z.number(),
        buyerGroupId: z.number(),
        buyerGroupName: z.string(),
      })
      .nullable(),
    intersport: z
      .object({
        membershipNumber: z.number(),
        buyerGroupId: z.number(),
        buyerGroupName: z.string(),
      })
      .nullable(),
  }),
});

const payment = {
  getCustomerPaymentDetails: {
    queryKey: 'get-customer-payment-details',
    schema: getCustomerPaymentDetailsSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get('/payment/customer-payment-details', {
        params: {
          retailerId,
        },
      });
      return getCustomerPaymentDetailsSchema.parse(response.data);
    },
  },
};

export default payment;
