import { z } from 'zod';
import { axiosAuth } from '@libs/axios';
import { ProductVariantSchema } from '@api/schema';

const ProposalDraftSharedAttributes = z.object({
  parentUniqueRowId: z.string().nullable(),
  uniqueRowId: z.string(),
});

const ProposalProductRow = ProposalDraftSharedAttributes.extend({
  type: z.literal('productRow'),
  parentUniqueRowId: z.string().nullable(),
  uniqueRowId: z.string(),
  itemId: z.number(),
  hierarchy: z.array(z.string()).length(1),
  brandId: z.number(),
  brandName: z.string(),
  productId: z.string(),
  productName: z.string(),
  heroCategory: z.string().nullable(),
  category: z.string().nullable(),
  productType: z.string().nullable(),
  imageURL: z.string().nullable(),
});

const ProposalPrimaryVariantRow = ProposalDraftSharedAttributes.extend({
  type: z.literal('primaryVariantRow'),
  parentUniqueRowId: z.string(),
  uniqueRowId: z.string(),
  itemVariantId: z.number(),
  hierarchy: z.array(z.string()).length(2),
  brandId: z.number(),
  productId: z.string(),
  variantId: z.string(),
  variantValue: z.string().nullable(),
  variantImageURL: z.string().nullable(),
  colourPill: z
    .object({
      name: z.string(),
      hex: z.string().nullable(),
    })
    .nullable(),
  rrp: z.number(),
  whs: z.number(),
  appliedCollectionId: z.number().nullable(),
  collections: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      additionalDiscount: z.number(),
      hasOrderWindow: z.boolean(),
      orderStartDate: z.string().nullable(),
      orderEndDate: z.string().nullable(),
      deliveryStartDate: z.string().nullable(),
      deliveryEndDate: z.string().nullable(),
      whs: z.number(),
      type: z.enum(['scheduled', 'in-season', 'indent']),
    })
  ),
  requestedFor: z.string().nullable(),
  indentOnly: z.boolean(),
  error: z
    .object({
      name: z.string(),
      message: z.string(),
    })
    .optional(),
});

const ProposalSecondaryVariantRow = ProposalDraftSharedAttributes.extend({
  type: z.literal('secondaryVariantRow'),
  parentUniqueRowId: z.string(),
  uniqueRowId: z.string(),
  itemVariantId: z.number(),
  itemSKUDetailId: z.number().nullable(),
  hierarchy: z.array(z.string()).length(3),
  brandId: z.number(),
  productId: z.string(),
  variantId: z.string(),
  sku: z.string(),
  secondaryVariantValue: z.string(),
  stock: z.number().nullable(),
  quantity: z.number(),
  rrp: z.number().default(0),
  whs: z.number().default(0),
  storeToQuantityMap: z.record(z.number()),
});

export const getOrderProposalSchema = z.object({
  orderProposalId: z.number(),
  name: z.string(),
  retailerId: z.number(),
  retailerName: z.string(),
  isOpen: z.boolean(),
  orderProposalRows: z.array(
    z.discriminatedUnion('type', [
      ProposalProductRow,
      ProposalPrimaryVariantRow,
      ProposalSecondaryVariantRow,
    ])
  ),
  brandDetailsLookup: z.record(
    z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string(),
      logoURL: z.string().nullable(),
      buyerTerms: z.object({
        type: z.enum(['global', 'persona']),
        discount: z.number(),
        additionalDiscount: z.number(),
        moq: z.number(),
      }),
    })
  ),
});

const getDraftOrderProposalsResponseSchema = z.object({
  total: z.number(),
  orderProposals: z.array(
    z.object({
      orderProposalId: z.number(),
      name: z.string(),
      retailerId: z.number(),
      retailerName: z.string(),
      createdAt: z.string(),
      isOpen: z.boolean(),
    })
  ),
});

const mutationResponseSchema = z.object({});

const createOrderProposalRequestSchema = z.object({
  brandId: z.number(),
  retailerId: z.number(),
  name: z.string(),
  addVariants: z.array(z.object({ productId: z.string(), variantId: z.string() })),
});

const updateOrderProposalRequestSchema = z.object({
  orderProposalId: z.number(),
  addVariants: z.array(z.object({ productId: z.string(), variantId: z.string() })).optional(),
  addItemSKUDetails: z
    .array(
      z.object({
        sku: z.string(),
        productId: z.string(),
        variantId: z.string(),
        itemVariantId: z.number(),
        quantity: z.number(),
      })
    )
    .optional(),
  updateItemVariants: z
    .array(
      z.object({
        itemVariantId: z.number(),
        appliedCollectionId: z.number().nullable().optional(),
        requestedFor: z.string().nullable().optional(),
      })
    )
    .optional(),
  updateItemSKUDetails: z
    .array(
      z.object({
        itemSKUDetailId: z.number(),
        quantity: z.number(),
      })
    )
    .optional(),
  removeItemIds: z.array(z.number()).optional(),
  removeItemVariantIds: z.array(z.number()).optional(),
});

const confirmOrderProposalRequestSchema = z.object({
  orderProposalId: z.number(),
  storeId: z.number(),
  notes: z.string(),
  paymentType: z.string(),
});

const createCustomerStoreReturnSchema = z.object({
  id: z.number(),
  name: z.string(),
  workingHours: z.string().nullable(),
  contactName: z.string().nullable(),
  contactNumber: z.string().nullable(),
  storeNumber: z.string().nullable(),
  isDefault: z.boolean(),
});

const getCustomerStoresSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    workingHours: z.string().nullable(),
    storeNumber: z.string().nullable(),
    contactName: z.string().nullable(),
    contactNumber: z.string().nullable(),
    isDefault: z.boolean().nullable(),
    address: z.object({
      line1: z.string().nullable(),
      line2: z.string().nullable(),
      city: z.string().nullable(),
      state: z.string().nullable(),
      country: z.string().nullable(),
      postcode: z.string().nullable(),
      remarks: z.string().nullable().optional(),
    }),
  })
);

const getPaymentTypesForCustomerSchema = z.object({
  approvedPaymentTypes: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      displayName: z.string(),
    })
  ),
});

const searchVariantsCustomerSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(ProductVariantSchema),
});

const proposals = {
  searchVariantsCustomer: {
    queryKey: 'searchVariantsCustomer' as const,
    schema: searchVariantsCustomerSchema,
    query: async ({
      retailerId,
      brandIds = [],
      orderProposalId,
      topicPaths = [],
      searchTerm,
      skip,
      take,
    }: {
      retailerId: number;
      brandIds: string[] | number[];
      orderProposalId?: number;
      topicPaths?: string | string[];
      searchTerm?: string;
      skip: number;
      take: number;
    }) => {
      const response = await axiosAuth.get('/order-proposal/search-variants-for-customer', {
        params: {
          retailerId,
          ...(orderProposalId !== undefined && { orderProposalId }),
          ...(brandIds?.length > 0 && { brandIds: brandIds.join(',') }),
          ...(searchTerm && { searchTerm }),
          ...(topicPaths?.length > 0 && {
            topicPaths: Array.isArray(topicPaths) ? topicPaths.join(',') : topicPaths,
          }),
          ...(searchTerm && { searchTerm }),
          skip,
          take,
        },
      });
      return searchVariantsCustomerSchema.parse(response.data?.result);
    },
  },
  getDraftOrderProposals: {
    queryKey: 'getDraftOrderProposals',
    responseSchema: getDraftOrderProposalsResponseSchema,
    query: async ({
      brandId,
      searchTerm,
      skip,
      take,
    }: {
      brandId: number;
      searchTerm: string;
      skip: number;
      take: number;
    }) => {
      const response = await axiosAuth.get(`/order-proposal/get-draft-order-proposals`, {
        params: {
          brandId,
          skip,
          take,
          ...(searchTerm && { searchTerm }),
        },
      });
      return getDraftOrderProposalsResponseSchema.parse(response.data?.result);
    },
  },
  getOrderProposal: {
    queryKey: 'getOrderProposal',
    responseSchema: getOrderProposalSchema,
    query: async (id: number) => {
      const response = await axiosAuth.get(
        `/order-proposal/get-order-proposal?orderProposalId=${id}`
      );
      return getOrderProposalSchema.parse(response.data?.result);
    },
  },
  createOrderProposal: {
    mutationKey: 'createOrderProposal',
    requestSchema: createOrderProposalRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof createOrderProposalRequestSchema>) => {
      const response = await axiosAuth.post(`/order-proposal/create-order-proposal`, {
        ...payload,
        addProductIds: [],
      });
      return response.data;
    },
  },
  updateOrderProposal: {
    mutationKey: 'updateOrderProposal',
    requestSchema: updateOrderProposalRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof updateOrderProposalRequestSchema>) => {
      const response = await axiosAuth.post(`/order-proposal/update-order-proposal`, {
        orderProposalId: payload.orderProposalId,
        addProductIds: [],
        addVariants: payload.addVariants || [],
        addItemSKUDetails: payload.addItemSKUDetails || [],
        updateItemVariants: payload.updateItemVariants || [],
        updateItemSKUDetails: payload.updateItemSKUDetails || [],
        removeItemIds: payload.removeItemIds || [],
        removeItemVariantIds: payload.removeItemVariantIds || [],
      });
      return response.data;
    },
  },
  confirmOrderProposal: {
    mutationKey: 'confirmOrderProposal',
    requestSchema: confirmOrderProposalRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof confirmOrderProposalRequestSchema>) => {
      const response = await axiosAuth.post(`/order-proposal/confirm-order-proposal`, payload);
      return response.data?.result;
    },
  },
  sendOrderProposalForApproval: {
    mutationKey: 'sendOrderProposalForApproval',
    requestSchema: confirmOrderProposalRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof confirmOrderProposalRequestSchema>) => {
      const response = await axiosAuth.post(
        `/order-proposal/request-approval-for-order-proposal`,
        payload
      );
      return response.data?.result;
    },
  },
  createCustomerStore: {
    mutationKey: 'createCustomerStore',
    responseSchema: createCustomerStoreReturnSchema,
    mutation: async ({
      retailerId,
      brandId,
      name,
      workingHours,
      contactName,
      contactNumber,
      storeNumber,
      isDefault,
      address,
    }: {
      retailerId: number;
      brandId: number;
      name: string;
      workingHours?: string;
      contactName?: string;
      contactNumber?: string;
      storeNumber?: string;
      isDefault?: boolean;
      address: {
        line1: string;
        line2?: string;
        city: string;
        state: string;
        country: string;
        postcode: string;
        latitude?: number;
        longitude?: number;
        remarks?: string;
      };
    }) => {
      const response = await axiosAuth.post('/retailer/store/create-customer-store', {
        retailerId,
        brandId,
        name,
        workingHours,
        contactName,
        contactNumber,
        storeNumber,
        isDefault,
        address,
      });

      return createCustomerStoreReturnSchema.parse(response.data?.result?.store);
    },
  },
  getCustomerStores: {
    queryKey: 'getCustomerStores',
    schema: getCustomerStoresSchema,
    query: async ({ retailerId, brandId }: { retailerId: number; brandId: number }) => {
      const response = await axiosAuth.get('/retailer/store/get-customer-stores', {
        params: {
          retailerId,
          brandId,
        },
      });
      return getCustomerStoresSchema.parse(response.data?.result?.stores);
    },
  },
  getPaymentTypesForCustomer: {
    queryKey: 'getPaymentTypesForCustomer',
    schema: getPaymentTypesForCustomerSchema,
    query: async ({ retailerId, brandId }: { retailerId: number; brandId: number }) => {
      const response = await axiosAuth.get(`/payment/get-payment-types-for-customer`, {
        params: {
          retailerId,
          brandId,
        },
      });
      return getPaymentTypesForCustomerSchema.parse(response.data?.result);
    },
  },
};

export type proposals = typeof proposals;

export default proposals;
