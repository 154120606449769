import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getCartReturnSchema = z.object({
  cartSummary: z
    .object({
      brandPercentage: z.record(z.string(), z.string()),
    })
    .nullable(),
  brandCarts: z.array(
    z.object({
      brand: z.object({
        brandId: z.number(),
        brandName: z.string(),
        discount: z.number().nullable().or(z.literal(0)),
        moq: z.number().nullable().or(z.literal(0)),
        logoURL: z.string().url().nullable(),
      }),
      persona: z
        .object({
          id: z.number(),
          moq: z.number(),
          discount: z.number(),
          additionalDiscount: z.number(),
        })
        .optional(),
      moq: z.number().nullable().or(z.literal(0)),
      oq: z.number(),
      discount: z.number(),
      additionalDiscount: z.number(),
      productCount: z.number(),
      skuCount: z.number(),
      cartItems: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          brandId: z.number(),
          image: z.string().url().nullable().optional(),
          primaryAttributeName: z.string().optional().nullable(),
          secondaryAttributeName: z.string().optional(),
          availableVariantAttributes: z.array(z.string()),
          paths: z.array(z.string()),
          variants: z.array(
            z.object({
              attribute: z.string().nullable(),
              colourPill: z
                .object({
                  name: z.string(),
                  hex: z.string().nullable(),
                })
                .nullable(),
              images: z
                .array(
                  z.object({
                    url: z.string().nullable(),
                  })
                )
                .nullable(),
              variants: z.array(
                z.object({
                  sku: z.string(),
                  vendorSku: z.string(),
                  price: z.number().nullable(),
                  stock: z.number().nullable(),
                  attribute: z.string().nullable(),
                  quantity: z.number(),
                  whs: z.number(),
                })
              ),
              requestedFor: z.string().datetime().optional(),
              appliedCollectionId: z.number().nullable().optional(),
              indentOnly: z.boolean(),
              appliedCollection: z
                .object({
                  id: z.number(),
                  name: z.string(),
                  additionalDiscount: z.number().nullable(),
                  type: z.enum(['scheduled', 'in-season', 'indent']).optional(),
                })
                .optional(),
            })
          ),
        })
      ),
    })
  ),
});

const getCurrentCartAmountReturnSchema = z.object({
  moq: z.number(),
  oq: z.number(),
});

const cart = {
  addVariants: {
    mutationKey: 'addVariants',
    mutation: async ({
      retailerId,
      cart,
    }: {
      retailerId: number;
      cart: {
        sku: string;
        quantity: number;
        requestedFor: string;
        appliedCollectionId?: number; // only need to pass if it is a collection
      }[];
    }) => {
      const response = await axiosAuth.post('/cart/add-variants', {
        retailerId,
        cart,
      });
      return response.data;
    },
  },
  getCart: {
    queryKey: 'getCart',
    schema: getCartReturnSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get('/cart/get-cart', {
        params: {
          retailerId,
        },
      });
      return getCartReturnSchema.parse(response.data);
    },
  },
  updateVariants: {
    mutationKey: 'updateVariants',
    mutation: async ({
      retailerId,
      cart,
    }: {
      retailerId: number;
      cart: {
        sku: string;
        quantity: number;
        requestedFor: string | null;
        appliedCollectionId?: number;
      }[];
    }) => {
      const response = await axiosAuth.post('/cart/update-variants', {
        retailerId,
        cart,
      });

      return response.data;
    },
  },
  emptyCart: {
    mutationKey: 'emptyCart',
    mutation: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.post('/cart/empty-cart', {
        retailerId,
      });

      return response.data;
    },
  },
  removeVariants: {
    mutationKey: 'removeVariants',
    mutation: async ({ retailerId, skus }: { retailerId: number; skus: string[] }) => {
      const response = await axiosAuth.post('/cart/remove-variants', {
        retailerId,
        skus,
      });

      return response.data;
    },
  },
  getCurrentCartAmount: {
    queryKey: 'getCurrentCartAmount',
    schema: getCurrentCartAmountReturnSchema,
    query: async ({ brandId, retailerId }: { brandId: number; retailerId: number }) => {
      const response = await axiosAuth.get('/cart/current-cart-amount', {
        params: {
          brandId,
          retailerId,
        },
      });

      return getCurrentCartAmountReturnSchema.parse(response.data);
    },
  },
};

export type cart = typeof cart;

export default cart;
