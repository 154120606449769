import { z } from 'zod';
import { axiosAuth } from '@libs/axios';
import { ProductVariantSchema, SearchProductSchema } from '@api/schema';

enum INVENTORY_STATE {
  INVENTORY = 'inventory',
  DRAFT = 'draft',
  ARCHIVE = 'archive',
}

export const collectionsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    deliveryStartDate: z.string().datetime().nullable(),
    deliveryEndDate: z.string().datetime().nullable(),
    orderStartDate: z.string().datetime().nullable(),
    orderEndDate: z.string().datetime().nullable(),
    hasOrderWindow: z.boolean(),
    description: z.string(),
    type: z.enum(['scheduled', 'in-season', 'indent']),
    additionalDiscount: z.number(),
    hasMarketingAssets: z.boolean(),
  })
);
export const getProductTypeSuggestionsSchema = z.object({
  productTypes: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      productCategoryPath: z.object({
        id: z.number(),
        path: z.string(),
        level1: z.string().nullable(),
        level2: z.string().nullable().optional(),
        level3: z.string().nullable().optional(),
        level4: z.string().nullable().optional(),
      }),
    })
  ),
});

export const getCategoriesSuggestionsSchema = z.object({
  userCategories: z.array(
    z.object({
      id: z.number(),
      path: z.string(),
      level1: z.string().nullable(),
      level2: z.string().nullable().optional(),
      level3: z.string().nullable().optional(),
    })
  ),
});

export const getUserCategoriesSuggestionsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.literal('Success'),
  result: getCategoriesSuggestionsSchema,
});

export const getTargetAudienceSuggestionsSchema = z.object({
  targetAudiences: z.array(
    z.object({
      id: z.number(),
      path: z.string(),
      level1: z.string().nullable(),
      level2: z.string().nullable(),
    })
  ),
});

export const getTargetAudienceSuggestionsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.literal('Success'),
  result: getTargetAudienceSuggestionsSchema,
});

export const getProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  brand: z.object({
    id: z.number(),
    name: z.string(),
    globalMoq: z.number().nullable(),
    globalDiscount: z.number().nullable(),
    leadTime: z.number().nullable(),
    logoUrl: z.string().url().nullable(),
    slug: z.string(),
  }),
  moq: z.number().nullable().optional(),
  discount: z.number().nullable().optional(),
  additionalDiscount: z.number().nullable().optional(),
  hasMarketingAssets: z.boolean(),
  productType: z
    .object({
      id: z.number().nullable(),
      name: z.string(),
    })
    .nullable(),
  userCategories: z
    .object({
      id: z.number(),
      path: z.string(),
      level1: z.string().nullable(),
      level2: z.string().nullable(),
    })
    .array(),
  targetAudiences: z
    .object({
      id: z.number(),
      path: z.string(),
      level1: z.string().nullable(),
      level2: z.string().nullable(),
    })
    .array(),
  productCategory:
    getProductTypeSuggestionsSchema.shape.productTypes.element.shape.productCategoryPath.nullable(),
  topics: z
    .array(
      z.object({
        id: z.number().nullable(),
        name: z.string().nullable(),
        path: z.string(),
      })
    )
    .nullable(),
  source: z.union([
    z.literal('system'), // exact string 'system'
    z.string(), // any other string
  ]),
  tagPaths: z
    .array(
      z.object({
        id: z.number().nullable(),
        displayName: z.string().nullable(),
        path: z.string(),
      })
    )
    .nullable(),
  sizeRange: z.string().nullable(),
  madeToOrder: z.boolean(),
  countryOfOrigin: z.string().nullable(),
  specifications: z.object({
    length: z.string().nullable(),
    width: z.string().nullable(),
    height: z.string().nullable(),
    weight: z.string().nullable(),
  }),
  // even though we get both Colour and Color from backend, when we update product we send "Colour"
  primaryAttribute: z.enum(['Colour', 'Color', 'Scent', 'Style']).nullable(),
  secondaryAttribute: z.enum(['Size']),
  commonAttributes: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  ),
  primaryVariantValues: z.array(z.string()),
  persona: z
    .object({
      id: z.number(),
      moq: z.number(),
      discount: z.number(),
      additionalDiscount: z.number(),
    })
    .optional(),
  variants: z.array(
    z.object({
      id: z.string(),
      value: z.string().nullable(),
      colourPill: z
        .object({
          name: z.string(),
          hex: z.string().nullable(),
        })
        .nullable(),
      published: z.boolean(),
      collections: collectionsSchema.optional(),
      variants: z.array(
        z.object({
          value: z.string().nullable(),
          sku: z.string(),
          vendorSku: z.string(),
          price: z.number(),
          stock: z.number().nullable(),
          preOrder: z.boolean(),
          onway: z.number(),
          isDefault: z.boolean(),
        })
      ),
      images: z
        .array(
          z.object({
            url: z.string().url(),
            key: z.string(),
            width: z.number().nullable().optional(),
            height: z.number().nullable().optional(),
            variants: z
              .array(
                z.object({
                  url: z.string().url(),
                  key: z.string(),
                  width: z.number().nullable().optional(),
                  height: z.number().nullable().optional(),
                })
              )
              .nullable(),
          })
        )
        .nullable()
        .optional(),
      indentOnly: z.boolean(),
    })
  ),
});

const ProductTypeSchema = z.object({
  productType: z.string(),
  topic: z.array(
    z.object({
      topicId: z.number(),
      path: z.string(),
      displayProductType: z.string(),
    })
  ),
});

const getSuggestedProductTypesSchema = z.array(ProductTypeSchema);

export type CategoriesSuggestionsType = z.infer<
  typeof getCategoriesSuggestionsSchema
>['userCategories'];

export type TargetAudienceSuggestionsType = z.infer<
  typeof getTargetAudienceSuggestionsSchema
>['targetAudiences'];

const getProductTypeReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.literal('Success'),
  result: z.object({
    productTypes: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
  }),
});

export const getProductTypeSuggestionsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.literal('Success'),
  result: getProductTypeSuggestionsSchema,
});

const getProductTagsSchema = z.array(
  z.object({
    id: z.number(),
    displayName: z.string(),
  })
);

const getSampleInventoryFileSchema = z.object({
  url: z.string(),
});

const getProductPathsReturnSchema = z.array(
  z.object({
    id: z.number(),
    path: z.string(),
    displayProductType: z.string(),
  })
);

const getProductTypeAttributesSchema = z.object({
  attributes: z.array(
    z.object({
      attribute: z.string(),
      values: z.array(z.string()),
    })
  ),
});

const getProductTypeAttributesSchemaV2 = z.object({
  attributes: z.array(
    z.object({
      name: z.string(),
      values: z.array(z.string()),
    })
  ),
});

const uploadProductImageSchema = z.object({
  key: z.string(),
  url: z.string().url(),
});

const createTagOutputSchema = z.object({
  id: z.number(),
  path: z.string(),
  displayName: z.string(),
});

const SuggestAdditionalProductTypesReturnSchema = z.array(
  z.object({
    productType: z.string(),
    topic: z.object({
      topicId: z.number(),
      path: z.string(),
      displayProductType: z.string(),
    }),
  })
);

const getProductPrimaryVariantFilterValuesSchema = z.object({
  scent: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
      hex: z.string().nullable(),
    })
  ),
  colour: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
      hex: z.string().nullable(),
    })
  ),
  style: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
      hex: z.string().nullable(),
    })
  ),
});

const baseFacetsSchema = z.object({
  name: z.string(),
  value: z.string(),
  count: z.number(),
});

export type FacetsType = z.infer<typeof baseFacetsSchema> & {
  children?: FacetsType[];
};

const facetSchema: z.ZodType<FacetsType> = baseFacetsSchema.extend({
  children: z.lazy(() => facetSchema.array().optional()),
});

export const searchVariantsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(
    ProductVariantSchema.extend({
      isPurchasable: z.boolean().optional(),
    })
  ),
  facets: z.object({
    targetAudiences: facetSchema.array(),
    userCategories: facetSchema.array(),
    productCategories: facetSchema.array(),
    productTypes: z.array(z.object({ name: z.string(), value: z.string(), count: z.number() })),
    colours: z.array(z.object({ name: z.string(), value: z.string(), count: z.number() })),
    scents: z.array(z.object({ name: z.string(), value: z.string(), count: z.number() })),
  }),
});

const searchProductsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(SearchProductSchema),
});

const CreateOrUpdateProductReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(201),
  message: z.literal('Product created successfully').or(z.literal('Product updated successfully')),
  result: z.object({}),
});

const getProductsV2ColumnsSchema = z.object({
  id: z.string().or(z.number().or(z.boolean())).nullable(),
  name: z.string().or(z.number().or(z.boolean())).nullable(),
  rrp: z.string().or(z.number().or(z.boolean())).nullable(),
  whs: z.string().or(z.number().or(z.boolean())).nullable(),
  productType: z.string().or(z.number().or(z.boolean())).nullable(),
  targetAudience: z.string().or(z.number().or(z.boolean())).nullable(),
  userCategory: z.string().or(z.number().or(z.boolean())).nullable(),
  productCategory: z.string().or(z.number().or(z.boolean())).nullable(),
  vendorSku: z.string().or(z.number().or(z.boolean())).nullable(),
  barcode: z.string().or(z.number().or(z.boolean())).nullable(),
  description: z.string().or(z.number().or(z.boolean())).nullable(),
  indentOnly: z.string().or(z.number().or(z.boolean())).nullable(),
  stock: z.string().or(z.number().or(z.boolean())).nullable(),
  onway: z.string().or(z.number().or(z.boolean())).nullable(),
  sizeRange: z.string().or(z.number().or(z.boolean())).nullable(),
  countryOfOrigin: z.string().or(z.number().or(z.boolean())).nullable(),
  length: z.string().or(z.number().or(z.boolean())).nullable(),
  width: z.string().or(z.number().or(z.boolean())).nullable(),
  height: z.string().or(z.number().or(z.boolean())).nullable(),
  weight: z.string().or(z.number().or(z.boolean())).nullable(),
  primaryAttribute: z.string().or(z.number().or(z.boolean())).nullable(),
  secondaryAttribute: z.string().or(z.number().or(z.boolean())).nullable(),
  'Material Content': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Care Instructions': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Material: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Key Ingredients': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Fit: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Occasion: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Length: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Pattern: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Range: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Aesthetic: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Season: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Sleeve: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Neckline: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Heel Height': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Stone Type': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Shape: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Features: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skin Type': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Formulation: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skin Concern': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Finish: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  Age: z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Hair Type': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Frame Shape': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Lens Colour': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Lens Type': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Frame Colour': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Lens Category': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'UV Protection': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Sunglass Size': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Bridge Height': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Lens Height': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Temple Length': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Lens Width': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Front Material': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Frame Material': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skate Deck Features': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skateboard Type': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skate Truck Details': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Truck Width': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Wheel Details': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Wheel Hardness': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Wheel Hub Placement': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Skateboarding Style': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Watch Display': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Watch Movement': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Watch Style': z.string().or(z.number().or(z.boolean())).nullable().optional(),
  'Watch Pattern': z.string().or(z.number().or(z.boolean())).nullable().optional(),
});

const getProductsV2Schema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  products: z.array(
    getProductsV2ColumnsSchema.extend({
      variants: z.array(
        getProductsV2ColumnsSchema.extend({
          images: z.array(
            z.object({
              url: z.string().nullable(),
              key: z.string().nullable(),
            })
          ),
          colourPill: z.string().nullable(),
          variants: z.array(getProductsV2ColumnsSchema),
        })
      ),
    })
  ),
});

export type ProductsV2ColumnsType = z.infer<typeof getProductsV2ColumnsSchema>;

const inventory = {
  getProduct: {
    queryKey: 'get-product',
    schema: getProductSchema,
    query: async ({ itemId, retailerId }: { itemId: string; retailerId?: number }) => {
      const response = await axiosAuth.get('/inventory/get-product', {
        params: {
          itemId,
          ...(retailerId && { retailerId }),
        },
      });
      return getProductSchema.parse(response.data);
    },
  },
  searchVariants: {
    queryKey: 'searchVariants' as const,
    schema: searchVariantsSchema,
    query: async ({
      retailerId,
      topicPaths = [],
      brandIds = [],
      searchTerm,
      moqMin,
      moqMax,
      discountMin,
      discountMax,
      leadTimeMin,
      leadTimeMax,
      valueIds = [],
      priceSegmentIds = [],
      shipsFrom = [],
      orderByField,
      orderByDirection,
      skip,
      take,
      indentOnly = 'ANY',
      isPurchasable = 'ANY',
      published,
      productCategoryPaths = [],
      targetAudiencePaths = [],
      userCategoryPaths = [],
      productTypes = [],
      colours = [],
      scents = [],
    }: {
      retailerId?: number;
      topicPaths?: string | string[];
      brandIds?: string[] | number[];
      searchTerm?: string;
      moqMin?: string | number;
      moqMax?: string | number | undefined;
      discountMin?: string | number;
      discountMax?: string | number;
      leadTimeMin?: string | number;
      leadTimeMax?: string | number;
      valueIds?: string[] | number[];
      priceSegmentIds?: string[] | number[];
      shipsFrom?: string[] | number[];
      orderByField?: string;
      orderByDirection?: string;
      skip: number;
      take: number;
      indentOnly?: 'ANY' | 'TRUE' | 'FALSE';
      isPurchasable?: 'ANY' | 'TRUE' | 'FALSE';
      published?: 'ANY' | 'TRUE' | 'FALSE';
      productCategoryPaths?: string | string[];
      targetAudiencePaths?: string | string[];
      userCategoryPaths?: string | string[];
      productTypes?: string | string[];
      colours?: string[];
      scents?: string[];
    }) => {
      const response = await axiosAuth.get('/inventory/search-variants', {
        params: {
          ...(retailerId !== undefined && { retailerId }),
          ...(brandIds?.length > 0 && { brandIds: brandIds.join(',') }),
          ...(searchTerm && { searchTerm }),
          ...(topicPaths?.length > 0 && {
            topicPaths: Array.isArray(topicPaths) ? topicPaths.join(',') : topicPaths,
          }),
          ...(moqMin && { moqMin }),
          ...(moqMax && { moqMax }),
          ...(discountMin && { discountMin }),
          ...(discountMax && { discountMax }),
          ...(leadTimeMin && { leadTimeMin }),
          ...(leadTimeMax && { leadTimeMax }),
          ...(valueIds?.length > 0 && { valueIds: valueIds.join(',') }),
          ...(priceSegmentIds?.length > 0 && {
            priceSegmentIds: priceSegmentIds.join(','),
          }),
          ...(searchTerm && { searchTerm }),
          ...(shipsFrom?.length > 0 && { shipsFrom: shipsFrom.join(',') }),
          ...(orderByField && { orderByField }),
          ...(orderByDirection && { orderByDirection }),
          ...(published && { published }),
          ...(targetAudiencePaths?.length > 0 && {
            targetAudiencePaths: Array.isArray(targetAudiencePaths)
              ? targetAudiencePaths.join(',')
              : targetAudiencePaths,
          }),
          ...(productCategoryPaths?.length > 0 && {
            productCategoryPaths: Array.isArray(productCategoryPaths)
              ? productCategoryPaths.join(',')
              : productCategoryPaths,
          }),
          ...(userCategoryPaths?.length > 0 && {
            userCategoryPaths: Array.isArray(userCategoryPaths)
              ? userCategoryPaths.join(',')
              : userCategoryPaths,
          }),
          ...(productTypes?.length > 0 && {
            productTypes: Array.isArray(productTypes) ? productTypes.join(',') : productTypes,
          }),
          ...((colours.length > 0 || scents.length > 0) && {
            primaryAttributeFilterValues: [...colours, ...scents].join(','),
          }),
          skip,
          take,
          indentOnly,
          isPurchasable,
        },
      });
      return searchVariantsSchema.parse(response.data?.result);
    },
  },
  getSearchProducts: {
    queryKey: 'getSearchProducts',
    schema: searchProductsSchema,
    query: async ({
      brandIds = [],
      take,
      skip,
      searchTerm,
      orderByField,
      orderByDirection,
      isSyncDraft = 'FALSE',
      isArchived = 'FALSE',
      indentOnly = 'ANY',
      isPurchasable = 'ANY',
      topicPaths,
    }: {
      brandIds?: number[];
      take: number;
      skip?: number;
      searchTerm?: string;
      orderByField?: 'NAME' | 'PRICE' | 'STOCK';
      orderByDirection?: 'ASC' | 'DESC';
      isSyncDraft?: 'ANY' | 'TRUE' | 'FALSE';
      isArchived?: 'ANY' | 'TRUE' | 'FALSE';
      indentOnly?: 'ANY' | 'TRUE' | 'FALSE';
      isPurchasable?: 'ANY' | 'TRUE' | 'FALSE';
      topicPaths?: string;
    }) => {
      const response = await axiosAuth.get('/inventory/v2/search-products', {
        params: {
          take,
          ...(brandIds?.length > 0 && { brandIds: brandIds.join(',') }),
          ...(searchTerm && { searchTerm }),
          ...(skip && { skip }),
          ...(orderByField && {
            orderByField: orderByDirection === undefined ? undefined : orderByField,
            published: 'ANY',
          }),
          ...(orderByDirection && {
            orderByDirection,
          }),
          isSyncDraft,
          isArchived,
          indentOnly,
          isPurchasable,
          ...(topicPaths && {
            topicPaths,
          }),
        },
      });
      // ensure the data we get back is in the correct shape
      return searchProductsSchema.parse(response.data?.result);
    },
  },
  getProductsV2: {
    queryKey: 'getProductsV2',
    schema: getProductsV2Schema,
    query: async ({
      brandIds = [],
      take,
      skip,
      searchTerm,
      orderByField,
      orderByDirection,
      isSyncDraft,
      isArchived,
      indentOnly = 'ANY',
      isPurchasable = 'ANY',
      topicPaths,
      retailerId,
      productCategoryPaths = [],
      targetAudiencePaths = [],
      userCategoryPaths = [],
      productTypes = [],
      colours = [],
      scents = [],
    }: {
      brandIds?: number[];
      take: number;
      skip?: number;
      searchTerm?: string;
      orderByField?: 'NAME' | 'PRICE' | 'STOCK' | 'DEFAULT';
      orderByDirection?: 'ASC' | 'DESC';
      isSyncDraft?: 'ANY' | 'TRUE' | 'FALSE';
      isArchived?: 'ANY' | 'TRUE' | 'FALSE';
      indentOnly?: 'ANY' | 'TRUE' | 'FALSE';
      isPurchasable?: 'ANY' | 'TRUE' | 'FALSE';
      topicPaths?: string;
      retailerId?: string;
      productCategoryPaths?: string | string[];
      targetAudiencePaths?: string | string[];
      userCategoryPaths?: string | string[];
      productTypes?: string | string[];
      colours?: string[];
      scents?: string[];
    }) => {
      const response = await axiosAuth.get('/inventory/v2/get-products', {
        params: {
          take,
          ...(brandIds?.length > 0 && { brandIds: brandIds.join(',') }),
          ...(searchTerm && { searchTerm }),
          ...(skip && { skip }),
          ...(orderByField && {
            orderByField: orderByDirection === undefined ? undefined : orderByField,
            published: 'ANY',
          }),
          ...(orderByDirection && {
            orderByDirection,
          }),
          isSyncDraft,
          isArchived,
          indentOnly,
          isPurchasable,
          ...(topicPaths && {
            topicPaths,
          }),
          ...(retailerId && {
            retailerId,
          }),
          ...(targetAudiencePaths?.length > 0 && {
            targetAudiencePaths: Array.isArray(targetAudiencePaths)
              ? targetAudiencePaths.join(',')
              : targetAudiencePaths,
          }),
          ...(productCategoryPaths?.length > 0 && {
            productCategoryPaths: Array.isArray(productCategoryPaths)
              ? productCategoryPaths.join(',')
              : productCategoryPaths,
          }),
          ...(userCategoryPaths?.length > 0 && {
            userCategoryPaths: Array.isArray(userCategoryPaths)
              ? userCategoryPaths.join(',')
              : userCategoryPaths,
          }),
          ...(productTypes?.length > 0 && {
            productTypes: Array.isArray(productTypes) ? productTypes.join(',') : productTypes,
          }),
          ...((colours.length > 0 || scents.length > 0) && {
            primaryAttributeFilterValues: [...colours, ...scents].join(','),
          }),
        },
      });
      // ensure the data we get back is in the correct shape
      return getProductsV2Schema.parse(response.data?.result);
    },
  },
  updatePrimaryVariant: {
    mutation: async ({
      itemId,
      skus,
      price,
    }: {
      itemId: string;
      skus: string[];
      price: number;
    }) => {
      const response = await axiosAuth.post('/inventory/update-variant-price', {
        itemId,
        skus,
        price,
      });

      const updatePrimaryVariantSuccessSchema = z.string();

      const updatePrimaryVariantErrorSchema = z.object({
        statusCode: z.number(),
        message: z.string(),
        error: z.string(),
      });

      if (updatePrimaryVariantSuccessSchema.safeParse(response.data).success) {
        return updatePrimaryVariantSuccessSchema.parse(response.data);
      }

      if (updatePrimaryVariantErrorSchema.safeParse(response.data)) {
        return updatePrimaryVariantErrorSchema.parse(response.data);
      }

      return response.data;
    },
  },
  updateSecondaryVariant: {
    mutation: async ({
      itemId,
      sku,
      stock,
      onWay,
    }: {
      itemId: string;
      sku: string;
      stock: number;
      onWay: number;
    }) => {
      const response = await axiosAuth.post('/inventory/update-variant-stock', {
        itemId,
        sku,
        stock,
        onway: onWay,
      });

      const updateSecondaryVariantSuccessSchema = z.string();

      const updateSecondaryVariantErrorSchema = z.object({
        statusCode: z.number(),
        message: z.string(),
        error: z.string(),
      });

      if (updateSecondaryVariantSuccessSchema.safeParse(response.data).success) {
        return updateSecondaryVariantSuccessSchema.parse(response.data);
      }

      if (updateSecondaryVariantErrorSchema.safeParse(response.data)) {
        return updateSecondaryVariantErrorSchema.parse(response.data);
      }

      return response.data;
    },
  },
  getSuggestedProductTypes: {
    queryKey: 'getSuggestedProductTypes',
    schema: getSuggestedProductTypesSchema,
    ProductTypeSchema: ProductTypeSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/inventory/suggest-product-type', {
        params: {
          searchTerm,
        },
      });

      return getSuggestedProductTypesSchema.parse(response.data);
    },
  },
  getProductType: {
    queryKey: 'getProductType',
    schema: getProductTypeReturnSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/inventory/get-product-type', {
        params: {
          searchTerm,
        },
      });

      return getProductTypeReturnSchema.parse(response.data);
    },
  },
  getProductTypeSuggestions: {
    queryKey: 'getProductTypeSuggestions',
    schema: getProductTypeSuggestionsReturnSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/inventory/v2/product-type-suggestions', {
        params: {
          searchTerm,
        },
      });

      return getProductTypeSuggestionsSchema.parse(response.data.result);
    },
  },
  getTargetAudienceSuggestions: {
    queryKey: 'getTargetAudienceSuggestions',
    schema: getTargetAudienceSuggestionsReturnSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/inventory/v2/target-audience-suggestions', {
        params: {
          searchTerm,
        },
      });

      return getTargetAudienceSuggestionsSchema.parse(response.data.result);
    },
  },
  getUserCategoriesSuggestions: {
    queryKey: 'getUserCategoriesSuggestions',
    schema: getUserCategoriesSuggestionsReturnSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/inventory/v2/user-categories-suggestions', {
        params: {
          searchTerm,
        },
      });

      return getCategoriesSuggestionsSchema.parse(response.data.result);
    },
  },
  getProductTypeAttributesV2: {
    queryKey: 'getProductTypeAttributesV2',
    schema: getProductTypeAttributesSchemaV2,
    query: async ({ productTypeId }: { productTypeId: string }) => {
      const response = await axiosAuth.get('/inventory/v2/get-product-type-attributes', {
        params: {
          productTypeId,
        },
      });
      return getProductTypeAttributesSchemaV2.parse(response.data.result);
    },
  },
  suggestAdditionalProductTypes: {
    queryKey: 'suggestAdditionalProductTypes',
    schema: SuggestAdditionalProductTypesReturnSchema,
    query: async ({ productType }: { productType: string }) => {
      const response = await axiosAuth.get('/inventory/suggest-additional-product-types', {
        params: {
          productType,
        },
      });

      return SuggestAdditionalProductTypesReturnSchema.parse(response.data);
    },
  },
  getProductPaths: {
    queryKey: 'getProductPaths',
    schema: getProductPathsReturnSchema,
    query: async ({ productTypeId }: { productTypeId: number }) => {
      const response = await axiosAuth.get('/inventory/get-product-paths', {
        params: {
          productTypeId,
        },
      });

      return getProductPathsReturnSchema.parse(response.data);
    },
  },
  getProductTypeAttributes: {
    queryKey: 'getProductTypeAttributes',
    schema: getProductTypeAttributesSchema,
    query: async ({ topicId }: { topicId: number }) => {
      const response = await axiosAuth.get('/inventory/get-product-type-attributes', {
        params: {
          topicId,
        },
      });
      return getProductTypeAttributesSchema.parse(response.data);
    },
  },
  createOrUpdateProduct: {
    schema: CreateOrUpdateProductReturnSchema,
    mutation: async ({
      isUpdatingProduct,
      isDraftMode,
      brandId,
      itemId,
      name,
      description,
      tagIds,
      primaryAttributeName,
      secondaryAttributeName,
      countryOfOrigin,
      madeToOrder,
      weight,
      height,
      width,
      length,
      attributes,
      variants,
      productCategoryId,
      userCategoryIds = [],
      targetAudienceIds = [],
    }: {
      isUpdatingProduct: boolean;
      isDraftMode: boolean;
      brandId?: number;
      itemId?: string;
      name: string;
      description: string;
      tagIds: number[];
      primaryAttributeName: string | null;
      secondaryAttributeName?: string;
      countryOfOrigin: string;
      madeToOrder: boolean;
      weight: number;
      height: number;
      width: number;
      length: number;
      attributes: {
        attribute: string;
        value: string;
      }[];
      variants: {
        vendorSku: string;
        price: number;
        stock: number;
        onway: number;
        preOrder: boolean;
        sku?: string;
        secondaryAttributeValue: string;
        primaryAttributeValue: string | null;
        isPublished?: boolean; // required for update-product
        primaryVariantFilterValue?: string;
        images: {
          key: string;
        }[];
        indentOnly: boolean;
      }[];
      productCategoryId: number;
      userCategoryIds?: number[];
      targetAudienceIds?: number[];
    }) => {
      // when isUpdatingProduct is true, you must pass itemId
      // when isUpdatingProduct is false, you must pass brandId
      const apiPath = isUpdatingProduct ? '/inventory/update-product' : '/inventory/create-product';
      const response = await axiosAuth.post(apiPath, {
        ...(isUpdatingProduct
          ? {
              itemId,
              ...(isDraftMode
                ? {
                    isSyncDraft: true,
                    isArchived: false,
                  }
                : {
                    isSyncDraft: false,
                    isArchived: false,
                  }),
            }
          : {
              brandId,
            }),
        name,
        description,
        tagIds,
        ...(primaryAttributeName && { primaryAttributeName }),
        ...(secondaryAttributeName && { secondaryAttributeName }),
        countryOfOrigin,
        madeToOrder,
        weight,
        height,
        width,
        length,
        attributes,
        variants,
        productCategoryId,
        ...(userCategoryIds && { userCategoryIds }),
        ...(targetAudienceIds && { targetAudienceIds }),
      });

      return CreateOrUpdateProductReturnSchema.parse(response.data);
    },
  },
  uploadProductImage: {
    mutation: async ({ image }: { image: FormData }) => {
      const response = await axiosAuth.post('/inventory/upload-product-image', image, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return uploadProductImageSchema.parse(response.data);
    },
  },
  deleteUploadedProductImage: {
    mutation: async (data: { url: string }) => {
      const response = await axiosAuth.post('/inventory/delete-product-image', data);
      return response;
    },
  },
  updateAtomicVariantPublishStatus: {
    mutationKey: 'updateAtomicVariantPublishStatus',
    mutation: async ({
      itemId,
      newPublishStatus,
      skus,
    }: {
      itemId: string;
      newPublishStatus: boolean;
      skus: string[];
    }) => {
      const response = await axiosAuth.post(
        `/inventory/${newPublishStatus ? 'publish' : 'unpublish'}-variants`,
        {
          itemId,
          skus,
        }
      );
      return z.string().parse(response.data);
    },
  },
  uploadInventory: {
    mutation: async (formData: FormData) => {
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(percentCompleted);
        },
      };

      return await axiosAuth.post('/inventory/upload-inventory', formData, {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
  },

  exportCSV: {
    mutationKey: 'export-CSV-inventory',
    schema: z.string(),
    mutation: async ({
      brandId,
      isSyncDraft,
      isArchived,
    }: {
      brandId: number;
      isSyncDraft: 'TRUE' | 'FALSE' | 'ANY';
      isArchived: 'TRUE' | 'FALSE' | 'ANY';
    }) => {
      const response = await axiosAuth.get('/inventory/v2/export-csv', {
        params: {
          brandId,
          isSyncDraft,
          isArchived,
        },
      });
      return response.data;
    },
  },

  getProductTags: {
    queryKey: 'getProductTags',
    schema: getProductTagsSchema,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/tags/get-product-tags', {
        params: {
          searchTerm,
          isActive: true,
        },
      });

      return getProductTagsSchema.parse(response.data.tags);
    },
  },

  createProductTag: {
    mutation: async ({ displayName, brandId }: { displayName: string; brandId: boolean }) => {
      const response = await axiosAuth.post(`/tags/create-product-tag`, {
        displayName,
        brandId,
      });
      console.log('🚀 ~ file: inventory.ts:828 ~ response:', response.data);
      return createTagOutputSchema.parse(response.data.tag);
    },
  },
  getSampleInventoryFile: {
    queryKey: 'getSampleInventoryFile',
    schema: getSampleInventoryFileSchema,
    query: async () => {
      const response = await axiosAuth.get('management/brand/inventory-sample-file');
      return getSampleInventoryFileSchema.parse(response.data);
    },
  },
  getProductPrimaryVariantFilterValues: {
    queryKey: 'getProductPrimaryVariantFilterValues',
    schema: getProductPrimaryVariantFilterValuesSchema,
    query: async () => {
      const response = await axiosAuth.get('/inventory/variant-filter-values');
      return getProductPrimaryVariantFilterValuesSchema.parse(response.data);
    },
  },
  moveToInventory: {
    mutation: async (products: { productId: string; publishStatus: boolean }[]) => {
      const response = await axiosAuth.post(`/inventory/v2/change-state`, {
        products,
        targetState: INVENTORY_STATE.INVENTORY,
      });
      return response.data;
    },
  },
  archiveProducts: {
    mutation: async (products: { productId: string }[]) => {
      const response = await axiosAuth.post(`/inventory/v2/change-state`, {
        products,
        targetState: INVENTORY_STATE.ARCHIVE,
      });
      return response.data;
    },
  },
  unarchiveProducts: {
    mutation: async (products: { productId: string }[]) => {
      const response = await axiosAuth.post(`/inventory/v2/change-state`, {
        products,
        targetState: INVENTORY_STATE.DRAFT,
      });
      return response.data;
    },
  },
  bulkMarkIndent: {
    mutation: async ({
      indentOnly,
      variants,
    }: {
      indentOnly: boolean;
      variants: { productId: string; variantId: string }[];
    }) => {
      const response = await axiosAuth.post(`/inventory/v2/update-indent-only`, {
        indentOnly,
        variants,
      });
      return response.data;
    },
  },
};

export type inventory = typeof inventory;

export default inventory;
