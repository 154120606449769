import { axiosAuth } from '@libs/axios';

export const notification = {
  getNotifications: {
    queryKey: 'getNotifications',
    query: async ({ take, skip }: { take: number; skip: number }) => {
      const response = await axiosAuth.get(
        `/notification/get-notifications?take=${take}&skip=${skip}`
      );
      return response.data;
    },
  },
  readNotification: {
    mutation: async (notificationIds: number[]) => {
      const response = await axiosAuth.put('/notification/read-notifications', { notificationIds });
      return response.data;
    },
  },
  deleteNotification: {
    mutation: async (id: number) => {
      const response = await axiosAuth.post('/delete-notification', {
        id,
      });
      return response.data;
    },
  },
};
