import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const ProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  margin: z.number(),
  primaryAttribute: z.string().nullable(),
  secondaryAttribute: z.string(),
  primaryVariant: z.object({
    value: z.string().nullable(),
    colourPill: z
      .object({
        name: z.string(),
        hex: z.string().nullable(),
      })
      .nullable(),
    images: z
      .array(
        z.object({
          url: z.string(),
          key: z.string(),
          width: z.number().nullable().optional(),
          height: z.number().nullable().optional(),
          variants: z.array(
            z.object({
              url: z.string(),
              key: z.string(),
              width: z.number().nullable().optional(),
              height: z.number().nullable().optional(),
            })
          ),
        })
      )
      .nullable()
      .optional(),
    published: z.boolean(),
    secondaryVariants: z.array(
      z.object({
        value: z.string(),
        sku: z.string(),
        vendorSku: z.string(),
        rrp: z.number(),
        whs: z.number(),
        stock: z.number().nullable(),
        preOrder: z.boolean(),
        onway: z.number().nullable(),
      })
    ),
  }),
  brand: z.object({
    id: z.number(),
    name: z.string().nullable(),
    globalMoq: z.number().nullable(),
    globalDiscount: z.number().nullable(),
    leadTime: z.number().nullable(),
    logoUrl: z.string().nullable(),
  }),
  persona: z
    .object({
      id: z.number(),
      moq: z.number(),
      discount: z.number(),
      additionalDiscount: z.number(),
    })
    .optional(),
  discount: z.number().nullable(),
  additionalDiscount: z.number().nullable(),
});

const ProductSchemaSingleSize = z.object({
  id: z.string(),
  name: z.string(),
  margin: z.number(),
  primaryAttribute: z.string().nullable(),
  secondaryAttribute: z.string(),
  primaryVariant: z.object({
    value: z.string().nullable(),
    colourPill: z
      .object({
        name: z.string(),
        hex: z.string().nullable(),
      })
      .nullable(),
    images: z
      .array(
        z.object({
          url: z.string(),
          key: z.string(),
          width: z.number().nullable().optional(),
          height: z.number().nullable().optional(),
          variants: z.array(
            z.object({
              url: z.string(),
              key: z.string(),
              width: z.number().nullable().optional(),
              height: z.number().nullable().optional(),
            })
          ),
        })
      )
      .optional(),
    published: z.boolean(),
    secondaryVariant: z.object({
      value: z.string(),
      sku: z.string(),
      vendorSku: z.string(),
      rrp: z.number(),
      whs: z.number(),
      stock: z.number().nullable(),
      preOrder: z.boolean(),
      onway: z.number().nullable(),
    }),
  }),
  brand: z.object({
    id: z.number(),
    name: z.string().nullable(),
    globalMoq: z.number().nullable(),
    globalDiscount: z.number().nullable(),
    leadTime: z.number().nullable(),
    logoUrl: z.string().nullable(),
  }),
  persona: z
    .object({
      id: z.number(),
      moq: z.number(),
      discount: z.number(),
      additionalDiscount: z.number(),
    })
    .optional(),
  discount: z.number().nullable(),
  additionalDiscount: z.number().nullable(),
});

export const getDropshipCartSchema = z.object({
  brandCarts: z.array(
    z.object({
      brand: z.object({
        id: z.number(),
        name: z.string().nullable(),
        globalMoq: z.number().nullable(),
        globalDiscount: z.number().nullable(),
        leadTime: z.number().nullable(),
        logoUrl: z.string().nullable(),
      }),
      cart: z.array(ProductSchema),
    })
  ),
});

const getBuyerDropshipRequestsSchema = z.object({
  dropshipRequests: z
    .array(
      z.object({
        id: z.number(),
        createdAt: z.string(),
        brand: z.string(),
        status: z.string(),
        product: ProductSchemaSingleSize,
      })
    )
    .nullable(),
  totalCount: z.number(),
});

const getSellerDropshipRequestsSchema = z.object({
  dropshipRequests: z
    .array(
      z.object({
        id: z.number(),
        createdAt: z.string(),
        requestedBy: z.string(),
        status: z.string(),
        product: ProductSchemaSingleSize,
      })
    )
    .nullable(),
  totalCount: z.number(),
});

const getBuyerDropshipProposalsSchema = z.object({
  dropshipProposals: z
    .array(
      z.object({
        id: z.number(),
        createdAt: z.string(),
        status: z.object({
          name: z.string(),
          displayName: z.string(),
        }),
        brand: z.object({
          id: z.number(),
          name: z.string(),
        }),
        retailer: z.object({
          id: z.number(),
          name: z.string(),
        }),
        product: z.object({
          id: z.string(),
          brand: z.object({
            id: z.number(),
            name: z.string().nullable(),
            globalMoq: z.number().nullable(),
            globalDiscount: z.number().nullable(),
            leadTime: z.number().nullable(),
            logoUrl: z.string().nullable(),
          }),
          name: z.string(),
          description: z.string(),
          productType: z.object({
            id: z.number().nullable(),
            name: z.string(),
          }),
          topics: z.array(
            z.object({
              id: z.number().nullable(),
              path: z.string(),
              name: z.string().nullable(),
            })
          ),
          tagPaths: z.array(
            z.object({
              id: z.number().nullable(),
              displayName: z.string().nullable(),
              path: z.string(),
            })
          ),
          sizeRange: z.string().nullable(),
          madeToOrder: z.boolean(),
          countryOfOrigin: z.string().nullable(),
          specifications: z.object({
            length: z.string().nullable(),
            width: z.string().nullable(),
            height: z.string().nullable(),
            weight: z.string().nullable(),
          }),
          primaryAttribute: z.string().nullable(),
          secondaryAttribute: z.string(),
          commonAttributes: z.array(
            z.object({
              name: z.string(),
              value: z.string(),
            })
          ),
          primaryVariantValues: z.array(z.string()),
          primaryVariantColourPills: z
            .array(
              z.object({
                name: z.string(),
                hex: z.string().nullable(),
              })
            )
            .nullable(),
          variants: z.array(
            z.object({
              id: z.string(),
              value: z.string().nullable(),
              colourPill: z
                .object({
                  name: z.string(),
                  hex: z.string().nullable(),
                })
                .nullable(),
              images: z
                .array(
                  z.object({
                    url: z.string().url(),
                    key: z.string(),
                    width: z.number().nullable().optional(),
                    height: z.number().nullable().optional(),
                    variants: z.array(
                      z.object({
                        url: z.string().url(),
                        key: z.string(),
                        width: z.number().nullable().optional(),
                        height: z.number().nullable().optional(),
                      })
                    ),
                  })
                )
                .nullable(),
              published: z.boolean(),
              variants: z.array(
                z.object({
                  value: z.string().nullable(),
                  sku: z.string(),
                  vendorSku: z.string(),
                  price: z.number(),
                  stock: z.number().nullable(),
                  preOrder: z.boolean(),
                  onway: z.number().nullable(),
                  isDefault: z.boolean(),
                })
              ),
            })
          ),
        }),
      })
    )
    .nullable(),
  totalCount: z.number(),
});

const RetailerSearchReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.string(),
  result: z.object({
    retailers: z.array(
      z.object({
        name: z.string(),
        id: z.number(),
      })
    ),
  }),
});

const CreateDropshipProposalReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(201),
  message: z.string(),
  result: z.object({}),
});

const getSellerDropshipProposalsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.string(),
  result: z.object({
    totalCount: z.number(),
    dropshipProposals: z.array(
      z.object({
        id: z.number(),
        createdAt: z.string().datetime(),
        status: z.object({
          name: z.string(),
          displayName: z.string(),
        }),
        brand: z.object({
          id: z.number(),
          name: z.string(),
        }),
        retailer: z.object({
          id: z.number(),
          name: z.string(),
        }),

        product: z.object({
          id: z.string(),
          brand: z.object({
            id: z.number(),
            name: z.string().nullable(),
            globalMoq: z.number().nullable(),
            globalDiscount: z.number().nullable(),
            leadTime: z.number().nullable(),
            logoUrl: z.string().url().nullable(),
          }),
          name: z.string(),
          description: z.string(),
          productType: z.object({
            id: z.number().nullable(),
            name: z.string(),
          }),
          topics: z.array(
            z.object({
              id: z.number().nullable(),
              path: z.string(),
              name: z.string().nullable(),
            })
          ),
          tagPaths: z.array(
            z.object({
              id: z.number().nullable(),
              displayName: z.string().nullable(),
              path: z.string(),
            })
          ),
          sizeRange: z.string().nullable(),
          madeToOrder: z.boolean(),
          countryOfOrigin: z.string().nullable(),
          specifications: z.object({
            length: z.string().nullable(),
            width: z.string().nullable(),
            height: z.string().nullable(),
            weight: z.string().nullable(),
          }),
          primaryAttribute: z.enum(['Colour', 'Color', 'Scent', 'Style']).nullable(),
          secondaryAttribute: z.enum(['Size']),
          commonAttributes: z.array(
            z.object({
              name: z.string(),
              value: z.string(),
            })
          ),
          primaryVariantValues: z.array(z.string()),
          primaryVariantColourPills: z
            .array(
              z.object({
                name: z.string(),
                hex: z.string().nullable(),
              })
            )
            .nullable(),
          variants: z.array(
            z.object({
              value: z.string().nullable(),
              colourPill: z
                .object({
                  name: z.string(),
                  hex: z.string().nullable(),
                })
                .nullable(),
              images: z
                .array(
                  z.object({
                    url: z.string().url(),
                    key: z.string(),
                    width: z.number().nullable().optional(),
                    height: z.number().nullable().optional(),
                    variants: z.array(
                      z.object({
                        url: z.string().url(),
                        key: z.string(),
                        width: z.number().nullable().optional(),
                        height: z.number().nullable().optional(),
                      })
                    ),
                  })
                )
                .nullable(),
              published: z.boolean(),
              variants: z.array(
                z.object({
                  value: z.string().nullable(),
                  sku: z.string(),
                  vendorSku: z.string(),
                  price: z.number(),
                  stock: z.number().nullable(),
                  preOrder: z.boolean(),
                  onway: z.number().nullable(),
                  isDefault: z.boolean(),
                })
              ),
            })
          ),
        }),
      })
    ),
  }),
});

export const dropship = {
  retailerSearch: {
    schema: RetailerSearchReturnSchema,
    queryKey: 'retailerSearch' as const,
    query: async ({ searchTerm }: { searchTerm: string }) => {
      const response = await axiosAuth.get('/dropship/seller/retailers', {
        params: {
          searchTerm,
        },
      });
      return RetailerSearchReturnSchema.parse(response.data);
    },
  },
  createDropshipProposal: {
    schema: CreateDropshipProposalReturnSchema,
    mutation: async ({
      brandId,
      product,
      retailerId,
    }: {
      brandId: number;
      retailerId: number;
      product: {
        id: string;
        skus: string[];
      };
    }) => {
      const response = await axiosAuth.post('/dropship/create-proposal', {
        brandId,
        product,
        retailerId,
      });

      return CreateDropshipProposalReturnSchema.parse(response.data);
    },
  },
  addToDropshipCart: {
    mutationKey: 'addToDropshipCart',
    mutation: async ({
      retailerId,
      cart,
    }: {
      retailerId: number;
      cart: {
        sku: string;
      }[];
    }) => {
      const response = await axiosAuth.post('/dropship/add-variants', {
        retailerId,
        cart,
      });
      return response.data;
    },
  },
  removeFromDropshipCart: {
    mutationKey: 'removeFromDropshipCart',
    mutation: async ({
      retailerId,
      cart,
    }: {
      retailerId: number;
      cart: {
        sku: string;
      }[];
    }) => {
      const response = await axiosAuth.post('/dropship/remove-variants', {
        retailerId,
        cart,
      });
      return response.data;
    },
  },
  getDropshipCart: {
    queryKey: 'getDropshipCart',
    schema: getDropshipCartSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get('/dropship/get-cart', {
        params: {
          retailerId,
        },
      });
      return getDropshipCartSchema.parse(response.data?.result);
    },
  },
  emptyDropshipCart: {
    mutationKey: 'emptyDropshipCart',
    mutation: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.post('/dropship/empty-cart', {
        retailerId,
      });
      return response.data;
    },
  },
  createDropshipRequest: {
    mutationKey: 'createDropshipRequest',
    mutation: async ({
      retailerId,
      cart,
    }: {
      retailerId: number;
      cart: {
        sku: string;
      }[];
    }) => {
      const response = await axiosAuth.post('/dropship/create-dropship-request', {
        retailerId,
        cart,
      });
      return response.data;
    },
  },
  getBuyerDropshipRequests: {
    responseSchema: getBuyerDropshipRequestsSchema,
    queryKey: 'getBuyerDropshipRequests',
    query: async ({
      retailerId,
      status,
      take,
      skip,
    }: {
      retailerId: number;
      status: string;
      take: number;
      skip: number;
    }) => {
      const response = await axiosAuth.get('/dropship/buyer/get-requests', {
        params: {
          retailerId,
          status,
          take,
          skip,
        },
      });
      return getBuyerDropshipRequestsSchema.parse(response.data?.result);
    },
  },
  getBuyerDropshipProposals: {
    schema: getBuyerDropshipProposalsSchema,
    queryKey: 'getBuyerDropshipProposals',
    query: async ({
      retailerId,
      status,
      take,
      skip,
    }: {
      retailerId: number;
      status: string;
      take: number;
      skip: number;
    }) => {
      const response = await axiosAuth.get('/dropship/buyer/proposals', {
        params: {
          retailerId,
          status,
          take,
          skip,
        },
      });
      return getBuyerDropshipProposalsSchema.parse(response.data?.result);
    },
  },
  getSellerDropshipRequests: {
    responseSchema: getSellerDropshipRequestsSchema,
    queryKey: 'getSellerDropshipRequests',
    query: async ({
      brandId,
      status,
      take,
      skip,
    }: {
      brandId: number;
      status?: string;
      take: number;
      skip: number;
    }) => {
      const response = await axiosAuth.get('/dropship/seller/get-requests', {
        params: {
          brandId,
          ...(status && { status }),
          take,
          skip,
        },
      });
      return getSellerDropshipRequestsSchema.parse(response.data?.result);
    },
  },

  getSellerDropshipProposals: {
    queryKey: 'getSellerDropshipProposals' as const,
    schema: getSellerDropshipProposalsReturnSchema,
    query: async ({
      status,
      take,
      skip,
      brandId,
    }: {
      status?: string;
      take: number;
      skip: number;
      brandId: number;
    }) => {
      const response = await axiosAuth.get('/dropship/seller/proposals', {
        params: {
          brandId,
          ...(status && { status }),
          take,
          skip,
        },
      });

      return getSellerDropshipProposalsReturnSchema.parse(response.data);
    },
  },
  updateDropshipRequestStatus: {
    mutationKey: 'updateDropshipRequestStatus',
    mutation: async ({
      dropshipRequestId,
      status,
    }: {
      dropshipRequestId: number;
      status: string;
    }) => {
      const response = await axiosAuth.post('/dropship/update-dropship-request-status', {
        dropshipRequestId,
        status,
      });
      return response.data;
    },
  },
  approveProposal: {
    mutationKey: 'approveProposal',
    mutation: async ({ dropshipProposalId }: { dropshipProposalId: number }) => {
      const response = await axiosAuth.post('/dropship/buyer/approve-proposal', {
        id: dropshipProposalId,
      });
      return response.data;
    },
  },
  declineProposal: {
    mutationKey: 'declineProposal',
    mutation: async ({ dropshipProposalId }: { dropshipProposalId: number }) => {
      const response = await axiosAuth.post('/dropship/buyer/decline-proposal', {
        id: dropshipProposalId,
      });
      return response.data;
    },
  },
};
