import type { KlaviyoNextApiRequest } from '@pages/api/klaviyo/create-event';

import { axiosNextApi } from '@libs/axios';
import { ROUTES } from '@constants/route';

export const createEvent = async (
  eventName: KlaviyoNextApiRequest['eventName'],
  profileProperties: KlaviyoNextApiRequest['profileProperties'],
  eventProperties?: KlaviyoNextApiRequest['eventProperties']
) => {
  try {
    await axiosNextApi.post(ROUTES.NEXT_API.KLAVIYO.CREATE_EVENT, {
      eventName,
      profileProperties,
      ...(eventProperties && { eventProperties }),
      timestamp: new Date(),
    });
  } catch (err: any) {
    console.log(err?.message);
  }
};
