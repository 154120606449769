import { z } from 'zod';

export type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const PersonaSchema = z.object({
  id: z.number(),
  moq: z.number().nullable(),
  discount: z.number().nullable(),
  additionalDiscount: z.number().nullable(),
});

export const Terms = z.enum(['brand', 'system', 'persona']);

export const BuyerTerms = z.object({
  moq: z.number(),
  moqSource: Terms,
  moqSourceId: z.number(),
  discount: z.number(),
  discountSource: Terms,
  discountSourceId: z.number(),
  additionalDiscount: z.number(),
  additionalDiscountSource: Terms.nullable(),
  additionalDiscountSourceId: z.number().nullable(),
});

export const ProductVariantSchema = z.object({
  id: z.string(),
  variantId: z.string(),
  name: z.string(),
  brandId: z.number(),
  brandName: z.string(),
  productTypes: z.array(z.string()).nullable(),
  primaryAttributeName: z.string().nullable(),
  primaryAttributeValue: z.string().nullable(),
  images: z.array(z.string()).nullable(),
  rrp: z.number().nullable(),
  whs: z.number(),
  variants: z.array(
    z.object({
      sku: z.string(),
    })
  ),
  colourPills: z
    .array(
      z.object({
        name: z.string(),
        hex: z.string().nullable(),
      })
    )
    .nullable(),
  hasMultiplePrices: z.boolean(),
  indentOnly: z.boolean(),
});

export const SearchProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  productType: z.string().nullable(),
  productCategoryPath: z.string().nullable(),
  heroCategory: z.string().nullable(),
  category: z.string().nullable(),
  /*
  TODO:
    topics: z
    .array(
      z.object({
        id: z.number().nullable(),
        name: z.string().nullable(),
        path: z.string(),
      })
    )
    .nullable(),
  */
  topics: z.array(z.string()).nullable(),
  tags: z.array(z.string()).nullable(),
  primaryAttribute: z.string().nullable(),
  secondaryAttribute: z.string().nullable(),
  attributes: z
    .array(
      z.object({
        name: z.string(),
        value: z.string(),
      })
    )
    .nullable(),
  isSyncDraft: z.boolean(),
  isArchived: z.boolean(),
  source: z.union([
    z.literal('system'), // exact string 'system'
    z.string(), // any other string
  ]),
  variants: z.array(
    z.object({
      id: z.string(),
      primaryAttributeValue: z.string().nullable(),
      images: z.array(z.string()).nullable(),
      variants: z.array(
        z.object({
          sku: z.string(),
          vendorSku: z.string(),
          secondaryAttributeValue: z.string(),
          stock: z.number(),
          onway: z.number(),
          price: z.number().nullable(),
        })
      ),
      published: z.boolean(),
      colourPill: z
        .object({
          name: z.string(),
          hex: z.string().nullable(),
        })
        .nullable(),
      personas: z
        .array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        )
        .nullable(),
      indentOnly: z.boolean(),
      error: z.object({ name: z.string(), message: z.string() }).optional(),
      collections: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          isPublished: z.boolean(),
          deliveryStartDate: z.string().datetime().nullable(),
          deliveryEndDate: z.string().datetime().nullable(),
          orderStartDate: z.string().datetime().nullable(),
          orderEndDate: z.string().datetime().nullable(),
          hasOrderWindow: z.boolean(),
          description: z.string(),
          type: z.string(),
          additionalDiscount: z.number(),
          image: z.string().nullable().optional(),
          isPrivate: z.boolean(),
        })
      ),
    })
  ),
  hasError: z.boolean().optional(),
  brand: z.object({
    id: z.number(),
    discount: z.number().nullable(),
    leadTime: z.number().nullable(),
    logoURL: z.string().nullable(),
    moq: z.number().nullable(),
    name: z.string(),
  }),
  allHeroCategories: z.string().array(),
});
