import { z } from 'zod';
import { CollectionType } from '@components/collections/CollectionsTable/CollectionsTable';
import { axiosAuth } from '@libs/axios';
import { ProductVariantSchema } from '@api/schema';

const searchCollectionsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  collections: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      isPublished: z.boolean(),
      isPrivate: z.boolean(),
      deliveryStartDate: z.string().datetime().nullable(),
      deliveryEndDate: z.string().datetime().nullable(),
      orderStartDate: z.string().datetime().nullable(),
      orderEndDate: z.string().datetime().nullable(),
      hasOrderWindow: z.boolean(),
      description: z.string(),
      type: z.string(),
      additionalDiscount: z.number(),
      image: z.string().nullable().optional(),
    })
  ),
});

export const getPublicCollectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  deliveryStartDate: z.string().datetime().nullable(),
  deliveryEndDate: z.string().datetime().nullable(),
  orderStartDate: z.string().datetime().nullable(),
  orderEndDate: z.string().datetime().nullable(),
  hasOrderWindow: z.boolean(),
  image: z.string().nullable(),
  description: z.string(),
  type: z.string(),
  additionalDiscount: z.number(),
  brandId: z.number().optional(),
  isPublished: z.boolean(),
  products: z.object({
    pageInfo: z.object({
      total: z.number(),
      count: z.number(),
    }),
    results: z.array(ProductVariantSchema),
  }),
});

const getCollectionDetailsSchema = z.object({
  id: z.number(),
  name: z.string(),
  deliveryStartDate: z.string().nullable(),
  deliveryEndDate: z.string().nullable(),
  orderStartDate: z.string().nullable(),
  orderEndDate: z.string().nullable(),
  hasOrderWindow: z.boolean(),
  image: z.string().nullable(),
  description: z.string(),
  type: z.string(),
  additionalDiscount: z.number(),
  brandId: z.number().optional(),
  isPublished: z.boolean(),
  isPrivate: z.boolean(),
});

const searchCollectionMemberVariantsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(
    ProductVariantSchema.extend({
      isPurchasable: z.boolean().optional(),
    })
  ),
});

const searchCollectionNewVariantsSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  results: z.array(
    ProductVariantSchema.extend({
      isPurchasable: z.boolean(),
    })
  ),
});

const searchNewCollectionRetailersSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  retailers: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      location: z.string().nullable(),
      productCategories: z.array(z.object({ id: z.number(), name: z.string() })),
      userCategories: z.array(z.object({ id: z.number(), name: z.string() })),
      pricing: z.object({ id: z.number(), name: z.string() }).nullable(),
      targetAudience: z.array(z.object({ id: z.number(), name: z.string() })),
      values: z.array(z.object({ id: z.number(), name: z.string(), imageURL: z.string() })),
      storeSize: z.object({ id: z.number(), name: z.string() }).nullable(),
      spot: z.object({ id: z.number(), name: z.string() }).nullable(),
      topography: z.object({ id: z.number(), name: z.string() }).nullable(),
    })
  ),
});

const searchCollectionMemberPersonasSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  personas: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      image: z.string().url().nullable(),
      discount: z.number().nullable(),
      additionalDiscount: z.number().nullable(),
    })
  ),
});

type CollectionIdRequired = {
  collectionId: number;
  brandId?: never;
};

type BrandIdRequired = {
  collectionId?: never;
  brandId: number;
};

type ExtendCollectionSearch<T> = T & (CollectionIdRequired | BrandIdRequired);

const collection = {
  searchCollections: {
    queryKey: 'searchCollections',
    schema: searchCollectionsSchema,
    query: async ({
      brandId,
      skip = 0,
      take = 20,
      searchTerm,
      types,
    }: {
      brandId: number;
      skip?: number;
      take?: number;
      searchTerm?: string;
      types?: CollectionType[];
    }) => {
      const response = await axiosAuth.get('/collection/v2/search-collections', {
        params: {
          brandId,
          skip,
          take,
          ...(searchTerm && { searchTerm }),
          ...(types && { types }),
        },
      });
      return searchCollectionsSchema.parse(response.data?.result);
    },
  },
  createCollection: {
    schema: z.string(),
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/collection/v2/create-collection', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data?.result;
    },
  },
  updateCollection: {
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/collection/v2/update-collection', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  },
  duplicateCollection: {
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/collection/v2/duplicate-collection', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  },
  getPublicCollection: {
    queryKey: 'getPublicCollection',
    schema: getPublicCollectionSchema,
    query: async ({
      collectionId,
      retailerId,
      count,
      after,
    }: {
      collectionId: number;
      retailerId?: number;
      count?: number;
      after?: string;
    }) => {
      const response = await axiosAuth.get('/collection/get-public-collection', {
        params: {
          collectionId,
          ...(retailerId && { retailerId }),
          count,
          after,
        },
      });
      return getPublicCollectionSchema.parse(response.data?.result);
    },
  },
  getCollectionDetails: {
    queryKey: 'getCollectionDetails',
    schema: getCollectionDetailsSchema,
    query: async (collectionId: number) => {
      const response = await axiosAuth.get('/collection/v2/get-details', {
        params: {
          collectionId,
        },
      });
      return getCollectionDetailsSchema.parse(response.data?.result?.details);
    },
  },
  searchCollectionMemberVariants: {
    queryKey: 'searchCollectionMemberVariants',
    schema: searchCollectionMemberVariantsSchema,
    query: async ({
      searchTerm,
      collectionId,
      take,
      skip,
      topicPaths,
      isIndentOnly = 'ANY',
      isPurchasable = 'ANY',
      orderByField,
      orderByDirection,
    }: {
      collectionId: number;
      skip: number;
      take: number;
      searchTerm: string;
      topicPaths: string | string[];
      isIndentOnly: 'ANY' | 'TRUE' | 'FALSE';
      isPurchasable: 'ANY' | 'TRUE' | 'FALSE';
      orderByField?: 'NAME' | 'PRICE' | 'STOCK';
      orderByDirection?: 'ASC' | 'DESC';
    }) => {
      const response = await axiosAuth.get('/collection/v2/search-member-variants', {
        params: {
          collectionId,
          skip,
          take,
          isIndentOnly,
          isPurchasable,
          ...(orderByField && { orderByField }),
          ...(orderByDirection && { orderByDirection }),
          ...(searchTerm && { searchTerm }),
          ...(topicPaths && { topicPaths }),
        },
      });
      return searchCollectionMemberVariantsSchema.parse(response.data?.result);
    },
  },
  searchCollectionMemberRetailers: {
    queryKey: 'searchCollectionMemberRetailers',
    schema: searchNewCollectionRetailersSchema,
    query: async ({
      skip,
      take,
      collectionId,
      searchTerm,
      filters,
    }: {
      skip: number;
      take: number;
      collectionId: number;
      searchTerm?: string;
      filters?: {
        productCategories: string[];
        userCategories: string[];
        targetAudience: string[];
        values: string[];
        topography: string[];
        size: string[];
        spot: string[];
        priceSegment?: string;
      };
    }) => {
      const {
        productCategories = [],
        userCategories = [],
        targetAudience = [],
        values = [],
        topography = [],
        size = [],
        spot = [],
        priceSegment = '',
      } = filters || {};

      const response = await axiosAuth.get('/collection/v2/search-member-retailers', {
        params: {
          skip,
          take,
          collectionId,
          ...(searchTerm && { searchTerm }),
          ...(productCategories?.length > 0 && {
            productCategories: productCategories.join(','),
          }),
          ...(userCategories?.length > 0 && {
            userCategories: userCategories.join(','),
          }),
          ...(targetAudience?.length > 0 && {
            targetAudience: targetAudience.join(','),
          }),
          ...(values?.length > 0 && {
            values: values.join(','),
          }),
          ...(priceSegment && {
            priceSegmentId: priceSegment,
          }),
          ...(size?.length > 0 && {
            size: size.join(','),
          }),
          ...(spot?.length > 0 && {
            spot: spot.join(','),
          }),
          ...(topography?.length > 0 && {
            topography: topography.join(','),
          }),
        },
      });
      return searchNewCollectionRetailersSchema.parse(response.data?.result);
    },
  },
  searchCollectionMemberPersonas: {
    queryKey: 'searchCollectionMemberPersonas',
    schema: searchCollectionMemberPersonasSchema,
    query: async ({
      skip,
      take,
      searchTerm,
      collectionId,
    }: {
      skip: number;
      take: number;
      collectionId: number;
      searchTerm?: string;
    }) => {
      const response = await axiosAuth.get('/collection/v2/search-member-personas', {
        params: {
          skip,
          take,
          collectionId,
          ...(searchTerm && { searchTerm }),
        },
      });
      return searchCollectionMemberPersonasSchema.parse(response.data?.result);
    },
  },
  searchCollectionNewPersonas: {
    queryKey: 'searchCollectionNewPersonas',
    schema: searchCollectionMemberPersonasSchema,
    query: async ({
      skip,
      take,
      searchTerm,
      collectionId,
      brandId,
    }: ExtendCollectionSearch<{
      skip: number;
      take: number;
      searchTerm?: string;
    }>) => {
      const response = await axiosAuth.get('/collection/v2/search-new-personas', {
        params: {
          skip,
          take,
          ...(brandId && { brandId }),
          ...(collectionId && { collectionId }),
          ...(searchTerm && { searchTerm }),
        },
      });
      return searchCollectionMemberPersonasSchema.parse(response.data?.result);
    },
  },
  searchCollectionNewVariants: {
    queryKey: 'searchCollectionNewVariants',
    schema: searchCollectionNewVariantsSchema,
    query: async ({
      searchTerm,
      collectionId,
      take,
      skip,
      topicPaths,
      isIndentOnly = 'ANY',
      isPurchasable = 'ANY',
      orderByField,
      orderByDirection,
    }: {
      collectionId: number;
      skip: number;
      take: number;
      searchTerm: string;
      topicPaths: string | string[];
      isIndentOnly: 'ANY' | 'TRUE' | 'FALSE';
      isPurchasable: 'ANY' | 'TRUE' | 'FALSE';
      orderByField?: 'NAME' | 'PRICE' | 'STOCK';
      orderByDirection?: 'ASC' | 'DESC';
    }) => {
      const response = await axiosAuth.get('/collection/v2/search-new-variants', {
        params: {
          collectionId,
          skip,
          take,
          isIndentOnly,
          isPurchasable,
          ...(orderByField && { orderByField }),
          ...(orderByDirection && { orderByDirection }),
          ...(searchTerm && { searchTerm }),
          ...(topicPaths && { topicPaths }),
        },
      });
      return searchCollectionNewVariantsSchema.parse(response.data?.result);
    },
  },
  removeVariants: {
    mutationKey: 'removeVariants',
    mutation: async ({
      collectionId,
      variantIds,
    }: {
      collectionId: number;
      variantIds: string[];
    }) => {
      const response = await axiosAuth.post('/collection/v2/remove-variants', {
        collectionId,
        variantIds,
      });
      return response.data;
    },
  },
  removeRetailers: {
    mutationKey: 'removeRetailers',
    mutation: async ({
      collectionId,
      retailerIds,
    }: {
      collectionId: number;
      retailerIds: number[];
    }) => {
      const response = await axiosAuth.post('/collection/v2/remove-retailers', {
        collectionId,
        retailerIds,
      });
      return response.data;
    },
  },
  removePersonas: {
    mutationKey: 'removePersonas',
    mutation: async ({
      collectionId,
      personaIds,
    }: {
      collectionId: number;
      personaIds: number[];
    }) => {
      const response = await axiosAuth.post('/collection/v2/remove-personas', {
        collectionId,
        personaIds,
      });
      return response.data;
    },
  },
  updateCollectionIsPublished: {
    mutationKey: 'updateCollectionIsPublished',
    schema: z.string(),
    mutation: async ({
      collectionId,
      newIsPublishedStatus,
    }: {
      collectionId: number;
      newIsPublishedStatus: boolean;
    }) => {
      let response = null;
      if (newIsPublishedStatus) {
        response = await axiosAuth.post('/collection/publish-collection', {
          collectionId,
        });
        return response.data;
      } else {
        response = await axiosAuth.post('/collection/unpublish-collection', {
          collectionId,
        });
      }

      return response.data;
    },
  },
  addVariants: {
    schema: z.string(),
    mutation: async ({
      collectionId,
      variantIds,
    }: {
      collectionId: number;
      variantIds: string[];
    }) => {
      await axiosAuth.post('/collection/v2/add-variants', {
        collectionId,
        variantIds,
      });
    },
  },
  addPersonas: {
    schema: z.string(),
    mutation: async ({
      collectionId,
      personaIds,
    }: {
      collectionId: number;
      personaIds: number[];
    }) => {
      await axiosAuth.post('/collection/v2/add-personas', {
        collectionId,
        personaIds,
      });
    },
  },
  deleteCollection: {
    schema: z.string(),
    mutation: async ({ collectionId }: { collectionId: number }) => {
      const response = await axiosAuth.post('/collection/delete-collection', {
        collectionId,
      });
      return response.data;
    },
  },
  searchNewCollectionRetailers: {
    queryKey: 'searchNewCollectionRetailers',
    schema: searchNewCollectionRetailersSchema,
    query: async ({
      skip,
      take,
      collectionId,
      brandId,
      searchTerm,
      filters,
    }: ExtendCollectionSearch<{
      skip: number;
      take: number;
      searchTerm?: string;
      filters?: {
        productCategories: string[];
        userCategories: string[];
        targetAudience: string[];
        values: string[];
        topography: string[];
        size: string[];
        spot: string[];
        priceSegment?: string;
      };
    }>) => {
      const {
        productCategories = [],
        userCategories = [],
        targetAudience = [],
        values = [],
        topography = [],
        size = [],
        spot = [],
        priceSegment = '',
      } = filters || {};

      const response = await axiosAuth.get('/collection/v2/search-new-retailers', {
        params: {
          skip,
          take,
          ...(brandId && { brandId }),
          ...(collectionId && { collectionId }),
          ...(searchTerm && { searchTerm }),
          ...(productCategories?.length > 0 && {
            productCategories: productCategories.join(','),
          }),
          ...(userCategories?.length > 0 && {
            userCategories: userCategories.join(','),
          }),
          ...(targetAudience?.length > 0 && {
            targetAudience: targetAudience.join(','),
          }),
          ...(values?.length > 0 && {
            values: values.join(','),
          }),
          ...(priceSegment && {
            priceSegmentId: priceSegment,
          }),
          ...(size?.length > 0 && {
            size: size.join(','),
          }),
          ...(spot?.length > 0 && {
            spot: spot.join(','),
          }),
          ...(topography?.length > 0 && {
            topography: topography.join(','),
          }),
        },
      });
      return searchNewCollectionRetailersSchema.parse(response.data?.result);
    },
  },
  addRetailers: {
    schema: z.string(),
    mutation: async ({
      collectionId,
      retailerIds,
    }: {
      collectionId: number;
      retailerIds: number[];
    }) => {
      await axiosAuth.post('/collection/v2/add-retailers', {
        collectionId,
        retailerIds,
      });
    },
  },
};

export type collection = typeof collection;

export default collection;
