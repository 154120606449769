import { axiosAuth } from '@libs/axios';

const tbng = {
  sendAccessRequest: {
    mutationKey: 'sendTbngAccessRequest',
    mutation: async ({
      retailerName,
      firstName,
      lastName,
      email,
      contactNumber,
    }: {
      retailerName?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      contactNumber?: string;
    }) => {
      const response = await axiosAuth.post('/management/retailer/send-tbng-request', {
        retailerName,
        firstName,
        lastName,
        email,
        ...(contactNumber && contactNumber?.length > 0 && { contactNumber }),
      });
      return response.data;
    },
  },
};

export default tbng;
