import { z } from 'zod';
import { axiosAuth } from '@libs/axios';
import { BuyerTerms, ProductVariantSchema } from '@api/schema';

/* Home page schema - START */
const landingPageSchema = z.object({
  promotionText: z.string(),
  promotionRedirectUrl: z.string(),
  carousels: z.array(
    z.object({
      heading: z.string(),
      subHeading: z.string(),
      primaryBtnText: z.string(),
      primaryBtnAction: z.string(),
      image: z.string(),
      primaryBtnBgColor: z.string(),
      primaryBtnTextColor: z.string(),
      cardBgColor: z.string(),
      cardTextColor: z.string(),
      cardBorderColor: z.string(),
      showExclusiveTag: z.boolean(),
      showVIPTag: z.boolean(),
    })
  ),
});

const recommendedBrandsSchema = z.object({
  id: z.number(),
  brandLogoURL: z.string().url().nullable(),
  brandName: z.string(),
  brandSlogan: z.string(),
  brandImageURL: z.string().url(),
  url: z.string().url(),
  description: z.string(),
  moq: z.number(),
  discount: z.number().nullable(),
  priceSegment: z.union([z.null(), z.string(), z.object({ id: z.number(), name: z.string() })]),
  slug: z.string().optional(),
});

const recommendedProductsSchema = z.object({
  id: z.string(),
  variantId: z.string(),
  indentOnly: z.boolean(),
  attribute: z.string().nullable(),
  brandId: z.number(),
  brandLogoURL: z.string().url().nullable(),
  brandName: z.string(),
  name: z.string(),
  imageURL: z.string().url(),
  description: z.string(),
  rrp: z.number(),
  moq: z.number(),
});

const recommendedCollectionsSchema = z.object({
  id: z.number(),
  brandId: z.number(),
  brandLogoURL: z.string().url().nullable(),
  brandName: z.string(),
  name: z.string(),
  imageURL: z.string().url(),
  description: z.string(),
  type: z.string(),
  additionalDiscount: z.number(),
});

const exploreCategorySchema = z.object({
  name: z.string(),
  imageURL: z.string(),
  path: z.string(),
});

const exploreCurationSchema = z.object({
  name: z.string(),
  imageURL: z.string(),
  path: z.string(),
});

const trendingTagSchema = z.object({
  name: z.string(),
  path: z.string(),
});

const brandShowcaseSchema = z.object({
  name: z.string(),
  path: z.string(),
  imageUrl: z.string(),
});

const customerTestimonialSchema = z.object({
  name: z.string(),
  profession: z.string(),
  text: z.string(),
  imageUrl: z.string(),
});

const requestCallbackSchema = z.object({
  heading: z.string(),
  subHeading: z.string(),
  primaryBtnText: z.string(),
  callbackUrl: z.string(),
});

const footerSchema = z.object({
  missionText: z.string(),
  ourStoryUrl: z.string(),
  partnershipUrl: z.string(),
  ourValuesUrl: z.string(),
  careersUrl: z.string(),
  helpCentreUrl: z.string(),
  contactUsUrl: z.string(),
  applyForCreditUrl: z.string(),
  linkedinUrl: z.string(),
  facebookUrl: z.string(),
  twitterUrl: z.string(),
  instagramUrl: z.string(),
  blogUrl: z.string(),
  pressUrl: z.string(),
  termsAndConditionsUrl: z.string(),
  privacyPolicyUrl: z.string(),
});

export const getHomepageCMSSchema = z.object({
  landingPageTop: landingPageSchema,
  recommendedBrands: z.array(recommendedBrandsSchema),
  recommendedProducts: z.array(recommendedProductsSchema),
  recommendedCollections: z.array(recommendedCollectionsSchema),
  exploreCategories: z.array(exploreCategorySchema),
  exploreCurations: z.array(exploreCurationSchema),
  trendingTags: z.array(trendingTagSchema),
  brandsShowcase: z.array(brandShowcaseSchema),
  customerTestimonial: z.array(customerTestimonialSchema),
  requestCallback: requestCallbackSchema,
  footer: footerSchema,
});
/* Home page schema - END */

const getCategorySplashPageContentSchema = z.object({
  categorySplashTop: z
    .object({
      nameOfCategory: z.string().optional(),
      viewAllBrandsUrl: z.string().optional(),
      featuredBrands: z.array(
        z.object({
          brandName: z.string(),
          brandLogo: z.string().url(),
          brandDescription: z.string(),
          brandParagraphs: z.string().optional().nullable(),
          brandCoverImage: z.string().url(),
          brandUrl: z.string(),
        })
      ),
    })
    .optional(),
  categorySplashRecommendations: z
    .object({
      viewRecommendationsUrl: z.string().optional(),
      recommendedProducts: z.array(
        z.object({
          productName: z.string(),
          productUrl: z.string(),
          productImage: z.string().url(),
        })
      ),
    })
    .optional(),
  categorySplashCategorySection: z
    .object({
      categoryTitle: z.string().optional(),
      categoryDescription: z.string().optional(),
      categoryData: z.array(
        z.object({
          categoryName: z.string(),
          categoryUrl: z.string().optional().nullable(),
          categoryImage: z.string().url().optional(),
        })
      ),
    })
    .optional(),
  categorySplashTrendingCollections: z
    .object({
      collectionsTitle: z.string().optional(),
      viewCollectionsUrl: z.string().optional(),
      trendingCollectionsData: z.array(
        z.object({
          collectionName: z.string(),
          collectionUrl: z.string().optional().nullable(),
          collectionImage: z.string().url().optional().nullable(),
        })
      ),
    })
    .optional(),
});

/* Brand Page schema - START */
export const getBrandPageContentSchema = z.object({
  brandPageTopData: z.object({
    brandLogo: z.string().url().optional().nullable(),
    brandOneLineStory: z.string().optional().nullable(),
    brandDescription: z.string().optional().nullable(),
    brandCoverImage: z.string().url().optional().nullable(),
    brandSpotlightImage: z.string().url().optional().nullable(),
    brandCampaignText: z.string().optional().nullable(),
  }),
  brandPageUniqueData: z.object({
    buyerTerms: BuyerTerms.optional(),
    id: z.number(),
    name: z.string(),
    moq: z.number().nullable().optional(),
    discount: z.number().nullable().optional(),
    shipsFrom: z.string().nullable().optional(),
    logoURL: z.string().nullable().optional(),
    priceSegment: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable()
      .optional(),
    userCategories: z
      .array(
        z.object({
          name: z.string(),
        })
      )
      .optional(),
    productCategories: z
      .array(
        z.object({
          name: z.string(),
        })
      )
      .optional(),
    values: z
      .array(
        z.object({
          name: z.string(),
          unicode: z.string(),
          imageURL: z.string().optional().nullable(),
        })
      )
      .optional(),
  }),
  brandPageFooter: z.object({
    footerTitle: z.string().optional().nullable(),
    contact: z
      .object({
        title: z.string().optional().nullable(),
        link: z.string().optional().nullable(),
      })
      .optional(),
    faq: z
      .object({
        title: z.string().optional().nullable(),
        link: z.string().optional().nullable(),
      })
      .optional(),
    helpCentre: z
      .object({
        title: z.string().optional().nullable(),
        link: z.string().optional().nullable(),
      })
      .optional(),
  }),
});

export const getBrandPageFeaturedProductsSchema = z.array(ProductVariantSchema);

export const getBrandPageExclusiveProductsSchema = z.array(ProductVariantSchema);

export const getBrandPageCollectionsSchema = z.array(
  z.object({
    collectionId: z.string().optional(),
    id: z.number(),
    name: z.string(),
    deliveryStartDate: z.string().datetime().nullable(),
    deliveryEndDate: z.string().datetime().nullable(),
    orderStartDate: z.string().datetime().nullable(),
    orderEndDate: z.string().datetime().nullable(),
    hasOrderWindow: z.boolean(),
    image: z.string().nullable(),
    description: z.string(),
    type: z.string(),
    additionalDiscount: z.number(),
    brandId: z.number().optional(),
    isPublished: z.boolean(),
    products: z.object({
      pageInfo: z.object({
        total: z.number(),
        count: z.number(),
      }),
      results: z.array(ProductVariantSchema),
    }),
  })
);

const getBrandIdReturnSchema = z.object({
  brandId: z.number(),
});

const cms = {
  getBrandId: {
    queryKey: 'getBrandId',
    schema: getBrandIdReturnSchema,
    query: async ({ slug }: { slug: string }) => {
      const response = await axiosAuth.get('/cms/get-brand-id', {
        params: {
          slug,
        },
      });

      return getBrandIdReturnSchema.parse(response.data?.result);
    },
  },
  getCategorySplashPageContent: {
    queryKey: 'getCategorySplashPageContent',
    schema: getCategorySplashPageContentSchema,
    query: async ({ categoryName }: { categoryName: string }) => {
      const response = await axiosAuth.get('/cms/get-category-splash-page-content', {
        params: {
          categoryName: categoryName,
        },
      });
      return getCategorySplashPageContentSchema.parse(response.data);
    },
  },
  getBrandPageContent: {
    queryKey: 'getBrandPageContent' as const,
    schema: getBrandPageContentSchema,
    query: async ({ brandId, retailerId }: { brandId: number; retailerId?: number }) => {
      const response = await axiosAuth.get('/cms/brand-page/basic-info', {
        params: {
          id: brandId,
          retailerId,
        },
      });
      return getBrandPageContentSchema.parse(response.data);
    },
  },
  getBrandPageFeaturedProducts: {
    queryKey: 'getBrandPageFeaturedProducts',
    schema: getBrandPageFeaturedProductsSchema,
    query: async ({ brandId, retailerId }: { brandId: number; retailerId?: number }) => {
      const response = await axiosAuth.get('/cms/brand-page/featured-products', {
        params: {
          id: brandId,
          ...(retailerId && { retailerId }),
        },
      });
      return getBrandPageFeaturedProductsSchema.parse(response.data?.result?.products);
    },
  },
  getBrandPageCollections: {
    queryKey: 'getBrandPageCollections',
    schema: getBrandPageCollectionsSchema,
    query: async ({ brandId, retailerId }: { brandId: number; retailerId?: number }) => {
      const response = await axiosAuth.get('/cms/brand-page/collections', {
        params: {
          id: brandId,
          ...(retailerId && { retailerId }),
        },
      });
      return getBrandPageCollectionsSchema.parse(response.data);
    },
  },
  getBrandPageExclusiveProducts: {
    queryKey: 'getBrandPageExclusiveProducts',
    schema: getBrandPageExclusiveProductsSchema,
    query: async ({ brandId, retailerId }: { brandId: number; retailerId: number }) => {
      const response = await axiosAuth.get('/cms/get-exclusive-products', {
        params: {
          retailerId: retailerId,
          brandId: brandId,
        },
      });
      return getBrandPageExclusiveProductsSchema.parse(response.data?.result?.products);
    },
  },
  updateBrandPageTopSection: {
    mutation: async (formData: FormData) => {
      const response = await axiosAuth.put('/cms/brand-page/top-section', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    },
  },
  updateFeaturedProducts: {
    mutation: async ({ brandId, variantIds }: { brandId: number; variantIds: string[] }) => {
      const response = await axiosAuth.put('/cms/brand-page/featured-products', {
        brandId,
        variantIds,
      });
      return response.data;
    },
  },
  updateRearrangedCollections: {
    mutation: async ({ brandId, collectionIds }: { brandId: number; collectionIds: string[] }) => {
      const response = await axiosAuth.put('/cms/brand-page/collections', {
        brandId,
        collectionIds,
      });
      return response.data;
    },
  },
};

export type cms = typeof cms;

export default cms;
