import z from 'zod';
import { axiosAuth } from '@libs/axios';

export const RetailerProfileSchema = z.object({
  id: z.number(),
  name: z.string(),
  companyId: z.number(),
  logoURL: z.string().nullable().optional(),
  priceSegmentId: z.number().nullable(),
  company: z.object({
    id: z.number(),
    name: z.string().nullable().optional(),
    abn: z.string().nullable().optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    address: z.string().nullable().optional(),
    isDropshipEligible: z.boolean().optional(),
  }),
  users: z.array(
    z.object({
      id: z.number(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
      role: z.object({
        id: z.number(),
        name: z.string(),
        userTypeId: z.number(),
        userType: z.object({ id: z.number(), name: z.string() }),
      }),
    })
  ),
  productCategories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  userCategories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  targetAudience: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  topographyId: z.number().nullable().optional(),
  sizeId: z.number().nullable().optional(),
  spotId: z.number().nullable().optional(),
  monthlyWHSSpendId: z.number().nullable().optional(),
  annualSalesNumber: z.string().nullable().optional(),
  values: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
        unicode: z.string(),
      })
    )
    .optional(),
});

export const profile = {
  updateRetailerProfile: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/retailer/update-retailer-profile', {
        ...data,
      });

      return response;
    },
  },
  getRetailerProfile: {
    queryKey: 'get-retailer-profile',
    schema: RetailerProfileSchema,
    query: async (retailerId: number): Promise<z.infer<typeof RetailerProfileSchema>> => {
      const response = await axiosAuth.get(
        `management/retailer/get-retailer-profile?id=${retailerId}`
      );
      // @ts-expect-error ignore
      if (response.data == '') return {};
      return RetailerProfileSchema.parse(response.data);
    },
  },
};
