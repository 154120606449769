import z from 'zod';
import { axiosAuth } from '@libs/axios';

const getRetailerAttributesSchema = z.object({
  productCategories: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  userCategories: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  targetAudience: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  priceSegment: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  values: z
    .object({
      id: z.number(),
      name: z.string(),
      unicode: z.string(),
      imageURL: z.string(),
    })
    .array(),
  topography: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  size: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  spot: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  monthlyWHSSpend: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
});

const getPreloginDataSchema = z.object({
  formDropdownValues: z.object({
    values: z.array(z.object({ id: z.number(), name: z.string(), imageURL: z.string() })),
    userCategories: z.array(z.object({ id: z.number(), name: z.string() })),
    productCategories: z.array(z.object({ id: z.number(), name: z.string() })),
    targetAudience: z.array(z.object({ id: z.number(), name: z.string() })),
    storeSizes: z.array(z.object({ id: z.number(), name: z.string() })),
    topographies: z.array(z.object({ id: z.number(), name: z.string() })),
    spots: z.array(z.object({ id: z.number(), name: z.string() })),
  }),
});

const account = {
  createRetailerAccount: {
    mutation: async (data: {
      companyName: string;
      retailerName: string;
      contactNumber: string;
      firstName: string;
      lastName: string;
      basedIn?: string;
      logoURL?: string;
    }) => {
      const response = await axiosAuth.post('/management/retailer/create-retailer-company', {
        ...data,
      });
      return response;
    },
  },
  getRetailerAttributes: {
    queryKey: 'get-brand-attributes',
    schema: getRetailerAttributesSchema,
    query: async () => {
      const response = await axiosAuth.get('management/retailer/get-all-retailer-attributes');
      return getRetailerAttributesSchema.parse(response.data);
    },
  },
  updateRetailerLogo: {
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/management/retailer/update-retailer-logo', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    },
  },
  getPreloginData: {
    queryKey: 'getPreloginData',
    schema: getPreloginDataSchema,
    query: async ({ brandSlug }: { brandSlug?: string }) => {
      const response = await axiosAuth.get('management/retailer/pre-login-dropdown-values', {
        params: { brandSlug },
      });

      return getPreloginDataSchema.parse(response.data);
    },
  },
};

export default account;
