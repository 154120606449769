import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

// customers schema
const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
});

const BrandSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const RetailerSchemaForCustomers = z.object({
  id: z.number(),
  name: z.string(),
  location: z.string().nullable(),
  productCategories: z.array(CategorySchema).nullable(),
  userCategories: z.array(CategorySchema).nullable(),
  pricing: z.union([
    z.null(),
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ]),
  targetAudience: z.array(CategorySchema).nullable(),
  values: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
        imageURL: z.string().url(),
      })
    )
    .nullable(),
  storeSize: z.union([
    z.null(),
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ]),
  spot: z.union([
    z.null(),
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ]),
  topography: z.union([
    z.null(),
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ]),
  numberOfOrders: z.number(),
  ordersValue: z.number(),
  proposalsPending: z.number(),
  itemsInCart: z.number(),
  hasEnabledOrderProposal: z.boolean(),
});

export const RetailerSchema = z.array(
  z.object({
    id: z.number(),
    brand: BrandSchema,
    retailer: RetailerSchemaForCustomers,
  })
);

const getCustomerSchema = z.object({
  result: z.object({
    count: z.number(),
    retailers: RetailerSchema,
  }),
});

//Non-customers

export const getNonCustomerSchema = z.object({
  result: z.object({
    count: z.number(),
    retailers: z.array(
      z.object({
        id: z.number(),
        location: z.string().nullable(),
        name: z.string(),
        productCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        userCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        targetAudience: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        values: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
            imageURL: z.string().url(),
          })
        ),
        pricing: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        storeSize: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        spot: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        topography: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
      })
    ),
  }),
});

type CustomerType = {
  searchTerm?: string;
  filters?: {
    productCategories: number[];
    userCategories: number[];
    targetAudience: number[];
    values: number[];
    topography: number[];
    size: number[];
    spot: number[];
    priceSegmentId: string;
  };
  skip: string | number;
  take: string | number;
  brandId: string;
};

type GetCustomersType = {
  searchTerm?: string;
  productCategories?: number[];
  userCategories?: number[];
  targetAudience?: number[];
  values?: number[];
  topography?: number[];
  size?: number[];
  spot?: number[];
  priceSegmentId?: string;
  skip: string | number;
  take: string | number;
  brandId: string;
};

const inviteCustomerSchema = z.object({
  brandId: z.number(),
  retailer: z.object({
    name: z.string(),
    companyName: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    contactNumber: z.string(),
  }),
});

export type InviteCustomerSchemaType = z.infer<typeof inviteCustomerSchema>;

export const customer = {
  getCustomers: {
    queryKey: 'getCustomers',
    schema: getCustomerSchema,
    query: async (data: GetCustomersType) => {
      const {
        brandId,
        take,
        skip,
        searchTerm,
        userCategories = [],
        targetAudience = [],
        values = [],
        topography = [],
        size = [],
        spot = [],
        priceSegmentId = '',
        productCategories = [],
      } = data;
      const response = await axiosAuth.get(`/customer/get-customers`, {
        params: {
          brandId,
          searchTerm,
          take,
          skip,
          userCategories: userCategories?.length > 0 ? userCategories.join(',') : null,
          targetAudience: targetAudience?.length > 0 ? targetAudience.join(',') : null,
          productCategories: productCategories?.length > 0 ? productCategories.join(',') : null,
          values: values?.length > 0 ? values.join(',') : null,
          topography: topography?.length > 0 ? topography.join(',') : null,
          size: size?.length > 0 ? size.join(',') : null,
          spot: spot?.length > 0 ? spot.join(',') : null,
          priceSegmentId: priceSegmentId || null,
        },
      });
      return getCustomerSchema.parse(response.data);
    },
  },
  getNonCustomers: {
    queryKey: 'getNonCustomers',
    schema: getNonCustomerSchema,
    query: async (data: CustomerType) => {
      const { brandId, take, skip, searchTerm, filters } = data;
      const response = await axiosAuth.get(
        `/customer/get-noncustomer-retailers?brandId=${brandId}&take=${take}&skip=${skip}&searchTerm=${searchTerm}`,
        {
          params: {
            productCategories:
              filters?.productCategories.length > 0 ? filters?.productCategories.join(',') : null,
            userCategories:
              filters?.userCategories.length > 0 ? filters?.userCategories.join(',') : null,
            targetAudience:
              filters?.targetAudience.length > 0 ? filters?.targetAudience.join(',') : null,
            values: filters?.values.length > 0 ? filters?.values.join(',') : null,
            topography: filters?.topography.length > 0 ? filters?.topography.join(',') : null,
            size: filters?.size.length > 0 ? filters?.size.join(',') : null,
            spot: filters?.spot.length > 0 ? filters?.spot.join(',') : null,
            priceSegmentId: filters?.priceSegmentId || null,
          },
        }
      );
      return getNonCustomerSchema.parse(response.data);
    },
  },
  addCustomers: {
    mutation: async (requestData: { retailerIds: number[]; brandId: number }) => {
      const response = await axiosAuth.post('/customer/add-customers', { ...requestData });
      return response.data;
    },
  },
  deleteCustomer: {
    mutation: async (requestData: { retailerId: number; brandId: number }) => {
      const response = await axiosAuth.delete('/customer/delete-customer', {
        data: { ...requestData },
      });
      return response.data;
    },
  },
  inviteCustomer: {
    mutation: async (data: InviteCustomerSchemaType) => {
      const response = await axiosAuth.post('/customer/invite-customer', data);
      return response.data;
    },
  },
};
