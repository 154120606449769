import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getSuppliersForRetailerRequestSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  suppliers: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      logoURL: z.string().url().nullable(),
      salesAmount: z.number(),
      isAccessible: z.boolean(),
    })
  ),
});

const getNonSuppliersForRetailerRequestSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  brands: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      logoURL: z.string().url().nullable(),
      isPrivate: z.boolean(),
      isAccessible: z.boolean(),
      productCategories: z
        .array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        )
        .nullable(),
      userCategories: z
        .array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        )
        .nullable(),
      priceSegment: z
        .object({
          id: z.number(),
          name: z.string().nullable(),
        })
        .nullable(),
      targetAudience: z
        .array(
          z.object({
            id: z.number(),
            name: z.string().nullable(),
          })
        )
        .nullable(),
      values: z
        .array(
          z.object({
            id: z.number(),
            name: z.string(),
            imageURL: z.string(),
          })
        )
        .nullable(),
      basedIn: z.string().nullable(),
    })
  ),
});

const suppliers = {
  getSuppliersForRetailer: {
    queryKey: 'getSuppliersForRetailer',
    requestSchema: getSuppliersForRetailerRequestSchema,
    query: async ({
      retailerId,
      skip,
      take,
      searchTerm,
    }: {
      retailerId: number;
      skip: number;
      take: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get(`/supplier/get-suppliers`, {
        params: { retailerId, skip, take, ...(searchTerm && { searchTerm }) },
      });
      return getSuppliersForRetailerRequestSchema.parse(response.data?.result);
    },
  },
  getNonSuppliersForRetailer: {
    queryKey: 'getNonSuppliersForRetailer',
    requestSchema: getNonSuppliersForRetailerRequestSchema,
    query: async ({
      retailerId,
      skip,
      take,
      searchTerm,
      productCategories = [],
      userCategories = [],
      targetAudience = [],
      priceSegmentIds = [],
      values = [],
      basedIn = [],
    }: {
      retailerId: number;
      skip: number;
      take: number;
      searchTerm: string;
      productCategories?: string[];
      userCategories?: string[];
      targetAudience?: string[];
      priceSegmentIds?: string[];
      values?: string[];
      basedIn?: string[];
    }) => {
      const response = await axiosAuth.get(`/supplier/get-non-supplier-brands`, {
        params: {
          retailerId,
          skip,
          take,
          ...(searchTerm && { searchTerm }),
          ...(productCategories?.length > 0 && {
            productCategories: productCategories.join(','),
          }),
          ...(userCategories?.length > 0 && {
            userCategories: userCategories.join(','),
          }),
          ...(targetAudience?.length > 0 && {
            targetAudience: targetAudience.join(','),
          }),
          ...(priceSegmentIds?.length > 0 && {
            priceSegmentIds: priceSegmentIds.join(','),
          }),
          ...(values?.length > 0 && {
            values: values.join(','),
          }),
          ...(basedIn?.length > 0 && {
            basedIn: basedIn.join(','),
          }),
        },
      });
      return getNonSuppliersForRetailerRequestSchema.parse(response.data?.result);
    },
  },
  addSuppliersForRetailer: {
    mutationKey: 'addSuppliersForRetailer',
    responseSchema: z.object({}),
    mutation: async (payload: { retailerId: number; brandIds: number[] }) => {
      const response = await axiosAuth.post(`/supplier/add-suppliers`, payload);
      return response.data;
    },
  },
  removeSupplierForRetailer: {
    mutationKey: 'removeSupplierForRetailer',
    responseSchema: z.object({}),
    mutation: async (payload: { retailerId: number; brandIds: number[] }) => {
      const response = await axiosAuth.delete(`/supplier/delete-suppliers`, {
        data: payload,
      });
      return response.data;
    },
  },
  inviteSupplier: {
    mutationKey: 'inviteSupplier',
    responseSchema: z.object({}),
    mutation: async (payload: {
      retailerId: number;
      brand: {
        name: string;
        companyName: string;
        firstName: string;
        lastName: string;
        email: string;
        contactNumber: string;
      };
    }) => {
      const response = await axiosAuth.post(`/supplier/invite-supplier`, payload);
      return response.data;
    },
  },
};

export default suppliers;
