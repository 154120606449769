import { z } from 'zod';
import { axiosNextApi } from '@libs/axios';
import { ROUTES } from '@constants/route';

const getBrandProductEventsResponseSchema = z.object({
  totalCount: z.number(),
  data: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      totalImpressionCount: z.number(),
      totalCartAddCount: z.number(),
      primaryAttribute: z.string().nullable(),
      variants: z.array(
        z.object({
          value: z.string().nullable(),
          imageURL: z.string().url().nullable().optional(),
          impressionCount: z.number(),
          cartAddCount: z.number(),
        })
      ),
    })
  ),
});

const analytics = {
  getBrandProductEvents: {
    queryKey: 'getBrandProductEvents',
    schema: getBrandProductEventsResponseSchema,
    query: async ({ brandId }: { brandId: number }) => {
      const response = await axiosNextApi.get(
        `${ROUTES.NEXT_API.POSTHOG.GET_BRAND_PRODUCT_EVENTS}`,
        {
          params: {
            brandId,
            // skip,
            // take,
          },
        }
      );
      return getBrandProductEventsResponseSchema.parse(response.data);
    },
  },
};

export default analytics;
