import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

export const BrandMarketHubFoldersSchema = z.object({
  brandFolders: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export const CollectionMarketHubFoldersSchema = z.object({
  collections: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export const ProductMarketHubFoldersSchema = z.object({
  pageInfo: z.object({
    total: z.number(),
    count: z.number(),
  }),
  products: z.array(
    z.object({
      id: z.string(),
      variantId: z.string(),
      name: z.string(),
      brandId: z.number(),
      brandName: z.string(),
      productTypes: z.array(z.string()).nullable(),
      primaryAttributeName: z.string().nullable(),
      primaryAttributeValue: z.string().nullable(),
      images: z.array(z.string()).nullable(),
      rrp: z.number().nullable(),
      whs: z.number(),
      variants: z.array(
        z.object({
          sku: z.string(),
        })
      ),
      colourPills: z
        .array(
          z.object({
            name: z.string(),
            hex: z.string().nullable(),
          })
        )
        .nullable(),
      hasMultiplePrices: z.boolean(),
      indentOnly: z.boolean(),
    })
  ),
});

export const MarketHubFolderListSchema = z.object({
  assets: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      url: z.string(),
    })
  ),
});

export const marketHub = {
  getBrandMarketHubFolders: {
    queryKey: 'getBrandMarketHubFolders',
    schema: BrandMarketHubFoldersSchema,
    query: async (brandId: number | undefined) => {
      const response = await axiosAuth.get(`marketing-hub/brand-folders?brandId=${brandId}`);
      return BrandMarketHubFoldersSchema.parse(response.data.result);
    },
  },
  getBrandMarketHubFolderFileList: {
    queryKey: 'getBrandMarketHubFolderFileList',
    schema: MarketHubFolderListSchema,
    query: async (brandFolderId: number) => {
      const response = await axiosAuth.get(
        `marketing-hub/brand-folder/assets?brandFolderId=${brandFolderId}`
      );
      return MarketHubFolderListSchema.parse(response.data.result);
    },
  },
  getCollectionMarketHubFolders: {
    queryKey: 'getCollectionMarketHubFolders',
    schema: CollectionMarketHubFoldersSchema,
    query: async ({
      brandId,
      hasAsset,
      searchTerm,
      retailerId,
    }: {
      brandId: number;
      hasAsset: string;
      searchTerm?: string;
      retailerId?: string;
    }) => {
      const response = await axiosAuth.get(
        `collection/search-collections?brandId=${brandId}&hasAssets=${hasAsset}`,
        {
          params: {
            ...(searchTerm && searchTerm?.length > 0 && { searchTerm }),
            ...(retailerId && retailerId?.length > 0 && { retailerId }),
          },
        }
      );
      return CollectionMarketHubFoldersSchema.parse(response.data.result);
    },
  },
  getCollectionMarketHubFolderFileList: {
    queryKey: 'getCollectionMarketHubFolderFileList',
    schema: MarketHubFolderListSchema,
    query: async (collectionId: number) => {
      const response = await axiosAuth.get(
        `marketing-hub/collection/assets?collectionId=${collectionId}`
      );
      return MarketHubFolderListSchema.parse(response.data.result);
    },
  },
  getProductMarketHubFolders: {
    queryKey: 'getProductMarketHubFolders',
    schema: ProductMarketHubFoldersSchema,
    query: async ({
      brandId,
      hasAsset,
      searchTerm,
      retailerId,
      skip,
      take,
    }: {
      brandId: number;
      hasAsset: string;
      searchTerm?: string;
      retailerId?: string;
      skip: number;
      take: number;
    }) => {
      const retailerIdQuery = `${retailerId ? `&retailerId=${retailerId}` : ''}`;
      const response = await axiosAuth.get(
        `marketing-hub/products?brandId=${brandId}&hasAssets=${hasAsset}${retailerIdQuery}`,
        {
          params: {
            ...(searchTerm && searchTerm?.length > 0 && { searchTerm }),
            skip,
            take,
          },
        }
      );
      return ProductMarketHubFoldersSchema.parse(response.data.result);
    },
  },
  getProductMarketHubFolderFileList: {
    queryKey: 'getProductMarketHubFolderFileList',
    schema: MarketHubFolderListSchema,
    query: async ({ brandId, productId }: { brandId: number; productId: number }) => {
      const response = await axiosAuth.get(
        `marketing-hub/product/assets?brandId=${brandId}&productId=${productId}`
      );
      return MarketHubFolderListSchema.parse(response.data.result);
    },
  },
  deleteMarktetHubAsset: {
    mutationKey: 'deleteMarktetHubAsset',
    mutation: async (data: { url: string }) => {
      const response = await axiosAuth.delete('marketing-hub/delete-asset', { data: data });
      return response;
    },
  },
  deleteAssetFromBrandFolder: {
    mutationKey: 'deleteAssetFromBrandFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/brand-folder/assets/delete', {
        params: { id },
      });
      return response;
    },
  },
  deleteAssetFromCollectionFolder: {
    mutationKey: 'deleteAssetFromCollectionFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/collection/assets/delete', {
        params: { id },
      });
      return response;
    },
  },
  deleteAssetFromProductFolder: {
    mutationKey: 'deleteAssetFromProductFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/product/assets/delete', {
        params: { id },
      });
      return response;
    },
  },
  addAssetForBrandFolder: {
    mutationKey: 'addAssetForBrandFolder',
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/marketing-hub/brand-folder/assets/update', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    },
  },
  addAssetForCollectionFolder: {
    mutationKey: 'addAssetForCollectionFolder',
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/marketing-hub/collection/assets/update', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    },
  },
  addAssetForProductFolder: {
    mutationKey: 'addAssetForProductFolder',
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/marketing-hub/product/assets/update', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    },
  },
  deleteBrandFolder: {
    mutationKey: 'deleteBrandFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/brand-folder/delete', {
        params: { brandFolderId: id },
      });
      return response;
    },
  },
  renameBrandFolder: {
    mutationKey: 'renameBrandFolder',
    mutation: async ({
      brandFolderName,
      brandFolderId,
    }: {
      brandFolderName: string;
      brandFolderId: number;
    }) => {
      const response = await axiosAuth.post('/marketing-hub/brand-folder/rename', {
        brandFolderName,
        brandFolderId,
      });
      return response;
    },
  },
  deleteCollectionFolder: {
    mutationKey: 'deleteCollectionFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/collection/delete', {
        params: { collectionId: id },
      });
      return response;
    },
  },
  deleteProductFolder: {
    mutationKey: 'deleteProductFolder',
    mutation: async (id: number) => {
      const response = await axiosAuth.delete('/marketing-hub/product/delete', {
        params: { productId: id },
      });
      return response;
    },
  },
};
