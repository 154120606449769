import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

export const recommendedBrandsSchema = z.object({
  id: z.number(),
  name: z.string(),
  logoURL: z.string().url().nullable(),
  spotlightImageURL: z.string().url().optional(),
  campaignText: z.string().optional(),
  slogan: z.string().optional(),
  slug: z.string(),
  url: z.string().optional(),
});

const getRecommendedBrandsSchema = z.object({
  result: z.object({
    brands: z.array(recommendedBrandsSchema),
  }),
});

export const recommendedCollectionsSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  imageURL: z.string().url().nullable(),
  brandId: z.number(),
  brandName: z.string(),
  brandLogoURL: z.string().url().nullable(),
});

const getRecommendedCollectionsSchema = z.object({
  result: z.object({
    collections: z.array(recommendedCollectionsSchema),
  }),
});

export const recommendedProductsSchema = z.object({
  id: z.string(),
  variantId: z.string(),
  indentOnly: z.boolean(),
  attribute: z.string().nullable(),
  name: z.string(),
  description: z.string(),
  imageURL: z.string().url().nullable().optional(),
  brandId: z.number(),
  brandName: z.string(),
  brandLogoURL: z.string().url().nullable(),
});

const getRecommendedProductsSchema = z.object({
  result: z.object({
    products: z.array(recommendedProductsSchema),
  }),
});

export const recommendations = {
  getRecommendedBrands: {
    queryKey: 'getRecommendedBrands',
    schema: getRecommendedBrandsSchema,
    query: async (retailerId: number) => {
      const response = await axiosAuth.get(
        `recommendation/get-recommended-brands?retailerId=${retailerId}&take=15&skip=0`
      );
      return getRecommendedBrandsSchema.parse(response.data);
    },
  },
  getRecommendedCollections: {
    queryKey: 'getRecommendedCollections',
    schema: getRecommendedCollectionsSchema,
    query: async (retailerId: number) => {
      const response = await axiosAuth.get(
        `recommendation/get-recommended-collections?retailerId=${retailerId}&take=15&skip=0`
      );
      return getRecommendedCollectionsSchema.parse(response.data);
    },
  },
  getRecommendedProducts: {
    queryKey: 'getRecommendedProducts',
    schema: getRecommendedProductsSchema,
    query: async (retailerId: number) => {
      const response = await axiosAuth.get(
        `recommendation/get-recommended-products?retailerId=${retailerId}&take=15&skip=0`
      );
      return getRecommendedProductsSchema.parse(response.data);
    },
  },
};
