import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const globalSearchSchema = z.object({
  products: z.array(
    z.object({
      productId: z.string(),
      name: z.string(),
      description: z.string(),
      brandName: z.string(),
      brandId: z.number(),
      productType: z.string(),
      topics: z.array(z.string()).nullable(),
      tags: z.array(z.string()).nullable(),
      primaryAttribute: z.string().optional().nullable(),
      secondaryAttribute: z.string().optional(),
      attributes: z.array(
        z.object({
          name: z.string(),
          value: z.string(),
        })
      ),
      variants: z.array(
        z.object({
          id: z.string(),
          primaryAttributeValue: z.string().nullable(),
          primaryAttributeFilterValue: z.string().nullable(),
          images: z.array(z.string()).nullable().optional(),
          published: z.boolean(),
          variants: z.array(
            z.object({
              vendorSku: z.string(),
              sku: z.string(),
              secondaryAttributeValue: z.string(),
              stock: z.number().nullable(),
              price: z.number().nullable(),
            })
          ),
        })
      ),
    })
  ),
  categories: z.array(
    z.object({
      id: z.string(),
      levels: z.array(z.string()),
      path: z.string(),
    })
  ),
  brands: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      logoUrl: z.string().nullable(),
      slug: z.string(),
    })
  ),
});

const search = {
  globalSearch: {
    schema: globalSearchSchema,
    queryKey: 'globalSearch',
    query: async ({
      searchTerm,
      take,
      retailerId,
      brandId,
    }: {
      searchTerm: string;
      take: number;
      retailerId?: number;
      brandId?: number;
    }) => {
      const response = await axiosAuth.get('/inventory/search-global', {
        params: {
          searchTerm,
          take,
          retailerId,
          brandId,
        },
      });
      return globalSearchSchema.parse(response.data.result);
    },
  },
};

export default search;
