import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getPaymentTypesForRetailerByAdminReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(200),
  message: z.literal('Success'),
  result: z.object({
    approvedPaymentTypes: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        displayName: z.string(),
        isEnabled: z.boolean(),
      })
    ),
  }),
});

const getPaymentTypesForRetailerReturnSchema = getPaymentTypesForRetailerByAdminReturnSchema;

const updateApprovedPaymentMethodsReturnSchema = z.object({
  success: z.literal(true),
  status: z.literal(201),
  message: z.literal('Successfully updated payments types for retailer'),
  result: z.object({}),
});

const paymentTypes = {
  getPaymentTypesForRetailerByAdmin: {
    queryKey: 'getPaymentTypesForRetailerByAdmin',
    schema: getPaymentTypesForRetailerByAdminReturnSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get(`/payment/admin/get-payment-types-for-retailer`, {
        params: {
          retailerId,
        },
      });

      return getPaymentTypesForRetailerByAdminReturnSchema.parse(response.data);
    },
  },

  getPaymentTypesForRetailer: {
    queryKey: 'getPaymentTypesForRetailer',
    schema: getPaymentTypesForRetailerReturnSchema,
    query: async ({ retailerId }: { retailerId: number }) => {
      const response = await axiosAuth.get(`/payment/get-payment-types-for-retailer`, {
        params: {
          retailerId,
        },
      });

      return getPaymentTypesForRetailerReturnSchema.parse(response.data);
    },
  },

  updateApprovedPaymentMethods: {
    schema: updateApprovedPaymentMethodsReturnSchema,
    mutation: async ({
      retailerId,
      newPaymentMethods,
    }: {
      retailerId: number;
      newPaymentMethods: {
        paymentTypeId: number;
        isEnabled: boolean;
      }[];
    }) => {
      const response = await axiosAuth.post(
        `/payment/update-approved-payment-methods-for-retailer`,
        {
          retailerId,
          newPaymentMethods,
        }
      );

      return updateApprovedPaymentMethodsReturnSchema.parse(response.data);
    },
  },
};

export default paymentTypes;
