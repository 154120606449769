import z from 'zod';
import { axiosAuth } from '@libs/axios';

export const BrandProfileSchema = z.object({
  id: z.number(),
  name: z.string(),
  companyId: z.number(),
  logoURL: z.string().nullable(),
  shipsFrom: z.string().nullable().optional(),
  moq: z.number().nullable().optional(),
  discount: z.number().nullable().optional(),
  leadTime: z.number().nullable().optional(),
  acceptsReturns: z.boolean().nullable().optional(),
  productIdentifierType: z.string().nullable().optional(),
  basedIn: z.string().nullable().optional(),
  madeToOrder: z.boolean().nullable().optional(),
  priceSegmentId: z.number().nullable().optional(),
  hasIntegrationEnabled: z.boolean(),
  company: z.object({
    id: z.number(),
    name: z.string().nullable().optional(),
    abn: z.string().nullable().optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    address: z.string().nullable().optional(),
  }),
  shipsTo: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  users: z.array(
    z.object({
      id: z.number(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
      role: z.object({
        id: z.number(),
        name: z.string(),
        userTypeId: z.number(),
        userType: z.object({ id: z.number(), name: z.string() }),
      }),
    })
  ),
  productCategories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  userCategories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  targetAudience: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  shipsToCountries: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .nullable()
    .optional(),
  values: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      unicode: z.string(),
    })
  ),
});

export const profile = {
  updateBrandProfile: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: async (data: any) => {
      const response = await axiosAuth.post('/management/brand/update-brand-profile', {
        ...data,
      });
      return response;
    },
  },
  getBrandProfile: {
    queryKey: 'get-brand-profile',
    schema: BrandProfileSchema,
    query: async (brandId: number): Promise<z.infer<typeof BrandProfileSchema>> => {
      const response = await axiosAuth.get(`management/brand/get-brand-profile?id=${brandId}`);
      // @ts-expect-error ignore
      if (response.data == '') return {};
      return BrandProfileSchema.parse(response.data);
    },
  },
};
