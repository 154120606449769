import { z } from 'zod';
import { axiosNextApi } from '@libs/axios';
import { ROUTES } from '@constants/route';

const getTokenSchema = z.object({
  token: z.string(),
});

const prismatic = {
  getToken: {
    queryKey: 'getPrismaticToken',
    schema: getTokenSchema,
    query: async () => {
      const response = await axiosNextApi.get(ROUTES.NEXT_API.PRISMATIC.GET_TOKEN);
      return getTokenSchema.parse(response.data);
    },
  },
};

export default prismatic;
