import { axiosAuth } from '@libs/axios';

export const brandMarketHub = {
  createBrandMarketHubFolder: {
    mutationKey: 'createBrandMarketHubFolder',
    mutation: async (data: {
      brandId: number;
      brandFolderName: string;
      assets: { name: string; url: string }[];
    }) => {
      const response = await axiosAuth.post('marketing-hub/brand-folder/assets', data);
      return response;
    },
  },
  createCollectionMarketHubFolder: {
    mutationKey: 'createCollectionMarketHubFolder',
    mutation: async (data: { collectionId: number; assets: { name: string; url: string }[] }) => {
      const response = await axiosAuth.post('marketing-hub/collection/assets', data);
      return response;
    },
  },
  createProductMarketHubFolder: {
    mutationKey: 'createProductMarketHubFolder',
    mutation: async (data: {
      productId: string;
      brandId: number;
      assets: { name: string; url: string }[];
    }) => {
      const response = await axiosAuth.post('marketing-hub/product/assets', data);
      return response;
    },
  },
};
