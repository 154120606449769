import z from 'zod';
import { axiosAuth } from '@libs/axios';

const getBrandAttributesSchema = z.object({
  productCategories: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  userCategories: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  targetAudience: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  priceSegment: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  values: z
    .object({
      id: z.number(),
      name: z.string(),
      unicode: z.string(),
      imageURL: z.string(),
    })
    .array(),
  shipsToCountries: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
});

const account = {
  createAccount: {
    mutation: async (data: {
      companyName: string;
      brandName: string;
      firstName: string;
      lastName: string;
      contactNumber: string;
      logoURL?: string;
    }) => {
      const response = await axiosAuth.post('/management/brand/create-brand-company', {
        ...data,
      });
      return response;
    },
  },
  getBrandAttributes: {
    queryKey: 'get-brand-attributes',
    schema: getBrandAttributesSchema,
    query: async () => {
      const response = await axiosAuth.get('management/brand/get-all-brand-attributes');
      return getBrandAttributesSchema.parse(response.data);
    },
  },
  updateBrandLogo: {
    mutation: async (data: FormData) => {
      const response = await axiosAuth.post('/management/brand/update-brand-logo', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    },
  },
};

export default account;
